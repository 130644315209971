<template lang="pug">
SideModal(v-model="isOpen" :max-width="!form.customer || !form.vehicle ? 700 : 1400")
  alert(ref="alert")
  OTFormSuccess(v-if="success" @back="reset" @closeModal="handleOTSuccess" :otID="otID" :selectedOT="selectedOT" :isQuotation="isQuotation" @quotationCreated="handleQuotationCreated($event)")
  div(v-else)
    h2.primary--text(v-if="selectedOT === null && !isQuotation") Nueva orden de trabajo
    h2.primary--text(v-if="isQuotation") Nueva Cotización
    h2.primary--text(v-if="selectedOT !== null") Nuevo presupuesto para OT N° {{selectedOT.id}}
    //- div(v-if="selectedOT === null && !isQuotation") Rellena o edita los datos de la nueva orden de trabajo
    div(v-if="isQuotation") Completa los datos para la nueva cotización.
    div(v-if="selectedOT !== null") Rellena o edita los datos del nuevo presupuesto
    div(v-if="formScheduleOT") Rellena o edita los datos de la nueva orden de trabajo
    div
      v-row
        //- Datos del vehículo
        v-col(:cols="!form.customer || !form.vehicle ? 12 : 5" :md="!form.customer || !form.vehicle ? 12 : 5")
          .my-2.primary--text(v-if="selectedOT === null && !formScheduleOT") Selecciona un vehículo o ingresa una nueva patente
          v-row.mb-2(dense v-if="selectedOT === null ")
            v-col(cols=12)
              v-autocomplete(
                :style="{'display': formScheduleOT? 'none' : 'block' }"
                v-model="form.vehicle"
                @change="onChangeVehicle"
                outlined
                :placeholder="loadingSearchPatent ? 'Buscando...' : 'Busca el vehículo por patente. Por ejemplo: ABCD12'"
                item-text="patent"
                return-object
                :hide-no-data="hideData"

                no-data-text="Escribe la patente del nuevo vehículo"
                :search-input.sync="search"
                :loading="isLoading"
                :items="items"

                :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
                hide-details
                @keyup.enter="() => { if (search && search.length == 6) { handleCreateNewVehicle(), searchPatentInPatenteChile(), search = '' } }"
                :disabled="loadingSearchPatent"
              )
                template(#append-item)
                  div.mr-2.ml-2
                    v-btn(color="primary" block @click="handleCreateNewVehicle(), searchPatentInPatenteChile()" :disabled="!search || search.length < 6") Agregar nuevo Vehículo
          v-row.mb-2.align-center(dense v-if="loadingSearchPatent")
            v-progress-circular(indeterminate color="primary" size="30")
            span.primary--text.ml-2 Buscando en Patentes Chile...
            v-btn.ml-4(depressed color="error" small text @click="cancelSearchPatent()") Cancelar
          v-row.mb-2.align-center(dense v-if="patent !== '' && !loadingSearchPatent")
              v-col
                p.error--text.mb-0(v-if="messageSearchPatent && !search && searchPatentFinished") {{messageSearchPatent}}

          //- v-row.mb-2(dense v-if="selectedOT === null && !formScheduleOT")
          //-   v-col(cols=6 sm=6)
          //-     v-btn.form-selector-btn(
          //-       :outlined="selectVehicleStatus!=='ON_CREATE'"
          //-       :class="{ 'lighten-2': selectVehicleStatus==='ON_CREATE' }"
          //-       height=56 block color="primary"
          //-       @click="selectVehicleStatus='ON_CREATE', form.vehicle=null, form.customer=null"
          //-     ) Nuevo Vehículo
          //-   v-col(cols=6 sm=6)
          //-     v-btn.form-selector-btn(
          //-       :outlined="selectVehicleStatus!=='ON_CREATE_CUSTOMER'"
          //-       :class="{ 'lighten-2': selectVehicleStatus==='ON_CREATE_CUSTOMER' }"
          //-       height=56 block color="primary"
          //-       @click="handleCreateNewCustomer"
          //-     ) Nuevo Cliente

          AppointmentToOTCard.mb-6.px-0(v-if="formScheduleOT" :appointment="formScheduleOT" )

          .mb-2
            .d-flex.justify-space-between(v-if="selectVehicleStatus==='SELECTED'")
              .mb-2.primary--text Datos del vehículo
              v-btn(icon @click="reset()" v-if="selectedOT === null")
                v-icon(v-if="!formScheduleOT") mdi-close-circle
            OTVehicleDetail(v-if="selectVehicleStatus==='SELECTED'" :vehicle="form.vehicle")
            VehicleForm(v-else-if="selectVehicleStatus==='ON_CREATE'" @save="onChangeVehicle" @newClient="selectVehicleStatus='ON_CREATE_CUSTOMER', form.vehicle=null, form.customer=null" :patent="patent" :dataVehicle="dataVehicle")
            CustomerAndVehicleForm(v-else-if="selectVehicleStatus==='ON_CREATE_CUSTOMER'" @save="onChangeVehicle" :patent="patent" :dataVehicle="dataVehicle")
          .mb-2
            .mb-2.primary--text(v-if="selectVehicleStatus==='SELECTED'") Datos del cliente
            SaleCustomerDetail(v-if="form.customer" :customer="form.customer" @showAlert="onChangeCustomer")
            CustomerForm(v-else-if="selectCutomerStatus==='ON_CREATE'" @save="selectCutomerStatus='SELECTED'")

        //- Panel de Opciones
        v-col(cols=7 md=7 style="margin-top: -10px;" v-show="form.customer || form.vehicle")
          v-form(ref="form" @submit.prevent="onSubmit")
            v-expansion-panels.pb-3(accordion flat hover color="primary")
              v-expansion-panel(style="background-color: #E4EAFF;")
                v-expansion-panel-header.px-0.py-0
                  v-icon.ml-2(style="color: #462DFA; flex: 0 0 auto") mdi-clipboard-check
                  p.mt-3.ml-3(style="color: #462DFA;") Más opciones
                v-expansion-panel-content(id="options-content")
                  <!-- Nested Panels -->
                  v-expansion-panels(accordion)

                    v-expansion-panel
                      v-expansion-panel-header.px-0.py-0
                        p.mt-3.ml-5(v-if="isQuotation") Nombre de la cotización (Opcional)
                        p.mt-3.ml-5(v-else) Nombre del presupuesto (Opcional)
                      v-expansion-panel-content
                        v-row
                          v-col(cols="8")
                            v-text-field.py-0(style="color: #462DFA" v-model="form.name" placeholder="Escriba aquí el nombre")

                    v-expansion-panel
                      v-expansion-panel-header.px-0.py-0
                        p.mt-3.ml-5(v-if="isQuotation") Duración estimada de la cotización (Opcional)
                        p.mt-3.ml-5(v-else) Duración estimada del presupuesto (Opcional)
                      v-expansion-panel-content
                        v-row
                          v-col(cols="8")
                            v-text-field.py-0(style="color: #462DFA" v-model="form.estimated_duration" placeholder="Escriba aquí la duración estimada")

                    v-expansion-panel(v-show="!isQuotation")
                      v-expansion-panel-header.px-0.py-0
                        p.mt-3.ml-5 Buscar presupuesto
                      v-expansion-panel-content
                        div
                          v-banner.banner.mb-5(elevation="0" color="#FFB573" rounded dark)
                            v-row
                              v-col(cols="6")
                                p Ahorra más tiempo
                                p Usando una plantilla o viendo el historial ya existente.
                              v-col(cols="6")
                                v-btn.mt-5.ml-5(elevation="0" color="#462DFA" dark @click="goToHistorial()" :disabled="form.vehicle === null") Buscar presupuesto

                    v-expansion-panel(v-show="!isQuotation")
                      v-expansion-panel-header.px-0.py-0
                        p.mt-3.ml-5 Convenio empresa
                      v-expansion-panel-content
                        div.agreement-section(v-if="!isQuotation")
                          v-row
                            v-col(cols="7")
                              h4 Convenio con empresa
                              p(style="font-size: 14px;") Selecciona la empresa a quién pertenece este cliente y aplica un convenio.
                              v-select(outlined :items="agreements" label="Convenios" dense v-model="agreementSelected" clearable @click:clear="handleClearAgreement")
                            v-col.d-flex.align-center.justify-center(cols="5")
                              v-btn(color="primary" large @click="isAgreement = true" :disabled="agreementSelected === null") Aplicar convenio
                        v-banner.mb-5(elevation="0" color="primary" rounded dark style="text-align: center;" v-show="isAgreement === true") Convenio aplicado
                          v-icon.ml-3 mdi-check-circle
            div.mt-3
              v-banner.mb-5(v-if="!isQuotation" v-show="$router.currentRoute.name === 'Presupuestos'" single-line color="success" rounded dark)
                p.my-0.mt-1 {{ isQuotationPage ? 'Se ha insertado la cotización correctamente.' : 'Se ha insertado la plantilla correctamente.'}}
                  v-icon.float-left.mr-3.mb-1(small) mdi-check-circle

            v-checkbox.mt-3.ml-2(
              v-if="!isQuotation"
              v-model="form.requires_evaluation"
              ref="evaluationCheckbox"
              label="¿Requiere diagnóstico?"
              :rules="[v => (v || this.form.services.length > 0) || 'Debe seleccionar al menos un servicio']"
            )
            //- p.red--text(v-if="!form.requires_evaluation && this.form.services.length === 0") Debe seleccionar al menos un servicio
            .d-flex.my-2(v-if="$route.name === 'Presupuestos' && form.name")
              h3.ml-3.primary--text Nombre plantilla:
              h3.ml-2.secondary--text "{{ form.name }}"
            //- Lista de Servicios
            OTServiceInput(
              v-model="form.services"
              :vehicle="form.vehicle"
              :showError="serviceIsMissing"
              :isQuotation="isQuotation"
            )
            //- Lista de items
            .div
              FavoriteTemplate.mb-4.ml-3(:form="form" :saveToLocalStorage="saveToLocalStorage")
              .mb-4
              v-textarea(v-model="form.clientComments" outlined label="Comentarios del cliente")

              //- Sección Descuentos
              div.mt-4.ma-3
                v-row.justify-end
                  .prepend-symbol $
                  v-text-field.mr-2.shrink(outlined label="Descuento" dense style="width: 110px" v-model="discountMoney" type="number" min="0" :disabled="discountPercentage !== null && discountPercentage !== ''")
                  .prepend-symbol %
                  v-text-field(outlined label="Descuento" dense class="shrink" style="width: 110px" v-model="discountPercentage" type="number" min="0" max="100" :disabled="discountMoney !== null && discountMoney !== ''")
              //- Sección Abono (solo para cotizaciones)
              div.mt-5.ma-3(v-if="isQuotationPage")
                v-row.justify-end
                  .prepend-symbol %
                  v-text-field(outlined label="Abono" dense class="shrink" style="width: 110px" v-model="form.deposit" type="number" min="1")
              //- Sección Totales
              div
                .total_price
                  label Subtotal: {{ subtotalPrice | currency }}
                .total_price
                  label Descuentos: -{{ discount | currency }}
                .total_price
                  label Total:
                  strong  {{totalPrice | currency}}
            .mb-4(v-if="showErrors")
              .red--text(v-for="error in errors") {{error}}
            v-btn.mt-2(block color="primary" x-large type="submit" v-if="selectedOT === null && !isQuotation" :loading="isSaving") + Guardar OT
            v-btn.mt-2(block color="primary" x-large v-if="!isQuotation && selectedOT !== null" @click="createNewEstimate" :loading="isSaving") + Crear Nuevo Presupuesto
            v-btn.mt-2(block color="primary" x-large v-if="isQuotation" type="submit" :loading="isSaving") + Crear Cotización
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CustomerForm from '../customer/CustomerForm.vue'
import SideModal from '../shared/SideModal.vue'
import SaleCustomerDetail from './subcomponents/SaleCustomerDetail.vue'
import OTServiceInput from './subcomponents/OTServiceInput.vue'
import OTFormSuccess from './subcomponents/OTFormSuccess.vue'
import OTVehicleDetail from './subcomponents/OTVehicleDetail.vue'
import VehicleForm from '../vehicle/VehicleForm.vue'
import CustomerAndVehicleForm from '../vehicle/CustomerAndVehicleForm.vue'
import FavoriteTemplate from './subcomponents/FavoriteTemplate.vue'
import alert from '../shared/alert.vue'
import { vMaska } from 'maska'
import { patentMaskFormat } from '@/utils/mask'
import AppointmentToOTCard from '../schedule/AppointmentToOTCard.vue'

export default {
  directives: { maska: vMaska },
  components: {
    CustomerForm,
    SideModal,
    SaleCustomerDetail,
    OTVehicleDetail,
    OTServiceInput,
    OTFormSuccess,
    VehicleForm,
    CustomerAndVehicleForm,
    FavoriteTemplate,
    alert,
    AppointmentToOTCard,
  },
  data () {
    return {
      selectVehicleStatus: 'UNSELECTED', // Estados: UNSELECTED, ON_CREATE, SELECTED
      selectCutomerStatus: 'UNSELECTED',
      form: {
        name: '',
        estimated_duration: '',
        services: [{
          id: null,
          name: '',
          price: 0,
          commission: 0.0,
          estimate: [],
          service_quantity: 1,
          saleItems: []
        }],
        vehicle: this.appointmentData || null,
        customer: null,
        clientComments: null,
        requires_evaluation: true,
        discount: null,
        agreement: null,
        deposit: 50
      },
      isOpen: false,
      searchVehicle: null,
      searchCutomer: null,
      showErrors: false,
      errors: [],
      success: false,
      isLoading: false,
      search: null,
      vehicleResults: [],
      otID: null,
      opened: 0,
      isQuotation: false,
      isAgreement: false,
      agreementSelected: null,
      agreements: [],
      discountMoney: null,
      discountPercentage: null,
      patent: null,
      hideData: true,
      isSaving: false,
      dataVehicle: null,
      loadingSearchPatent: false,
      acceptSearchPatent: true,
      messageSearchPatent: null,
      searchPatentFinished: false,
      creatingNewCustomer: false,
      appointmentData: null,
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => null
    },
    selectedOT: {
      type: Object,
      default: () => null
    },
    formScheduleOT: {
      type: Object,
      default: () => null
    },
  },
  methods: {
    ...mapActions('inventory', ['listAgreements']),
    ...mapActions('ot', ['getOT', 'createOT', 'createQuotation']),
    ...mapActions('estimate', ['createEstimate']),
    ...mapActions('customer', ['listCustomers', 'getCustomer']),
    ...mapActions('vehicle', ['listVehicles', 'searchPatent', 'createBrand', 'createVehicleModel']),
    ...mapActions('service', ['listServices']),
    ...mapActions('inventory', ['createAccountReceivableAgreement']),
    searchCustomer () {
      this.listCustomers({ parmas: { search: this.searchCustomer } })
    },
    onSearchVehicle () {
      this.listCustomers({ parmas: { search: this.searchVehicle } })
    },
    async onChangeCustomer () {
      const response = await this.getCustomer({ customerId: this.form.vehicle.customer })
      this.form.customer = response.data
      this.$refs.alert.handleShowAlert('success', 'Los datos del Cliente han sido editados', true, 'green')
    },
    async onChangeVehicle (vehicle) {
      this.form.vehicle = vehicle
      this.selectVehicleStatus = 'SELECTED'
      if (this.form.vehicle?.customer) {
        const response = await this.getCustomer({ customerId: this.form.vehicle.customer })
        this.form.customer = response.data
      }
      this.messageSearchPatent = null
    },
    async open (data = null) {
      if(this.formScheduleOT) {
        this.appointmentData = this.formScheduleOT
        this.search = this.appointmentData.vehicle.patent
        this.onChangeVehicle(this.items[0])
      }

      const myPatent = JSON.parse(localStorage.getItem('selectedPatent'))
      if(myPatent) {
        const myVehicle = JSON.parse(localStorage.getItem('selectedVehicle'))
        this.appointmentData = myVehicle
        this.search = myPatent
        // this.onChangeVehicle(myVehicle)

      }

      this.listServices()
      this.listAgreements().then(
        response => {
          this.agreements = response.data.map(
            (agreement) => {
              return {
                text: agreement.name,
                value: agreement.id
              }
            }
          )
        }
      )
      this.isOpen = true
      const page = this.$route.path.split('/')

      if (data) {
        if (Object.prototype.hasOwnProperty.call(data, 'newQuotation')) {
          this.reset()
          this.isQuotation = data.newQuotation
        } else {
          this.form.customer = data.customer_data
          this.form.vehicle = data.vehicle_data
          this.form.clientComments = data.clientComments
          this.form.discount = data.discount
          this.selectVehicleStatus = 'SELECTED'
        }
        // this.isQuotation = page[page.length - 1] === 'quotations'
      } else {
        this.reset()
        if (this.$route.name === 'Presupuestos' && this.isQuotationPage) {
          this.loadFromLocalStorage()
        } else if (page[page.length - 1] === 'quotations') {
          // this.isQuotationPage = true
        } else {
          this.removeDataLocalStorage()
        }
      }
    },
    async onSubmit () {
      this.isSaving = true
      this.showErrors = true
      if (!this.form.requires_evaluation) {
        if (!this.$refs.form.validate() || this.errors.length) {
          this.isSaving = false
          return
        }
      }
      if ((this.discountMoney !== null && this.discountMoney !== '') || (this.discountPercentage !== null && this.discountPercentage !== '')) {
        if (this.errors.length) {
          this.isSaving = false
          return
        }
      }

      const data = {
        status: this.isQuotation ? 'Cotización' : 'Ingresado',
        customer: this.form.customer.id,
        vehicle: this.form.vehicle.id,
        estimate: [this.getEstimateFormatedData()],
        appointment: this.appointmentData?.id || null,
      }

      const action = this.isQuotation ? this.createQuotation : this.createOT

      const otResponse = await action({ data })

      if (otResponse && otResponse.status >= 200 && otResponse.status < 300) {
        this.otID = otResponse.data.id
        this.$emit('save', otResponse.data)
        this.success = true
        this.isSaving = false
      } else {
        this.$refs.alert.handleShowAlert('error', 'Ha ocurrido un error al intentar guardar.', true, 'red')
        this.isSaving = false
      }
    },
    reset (data = null) {
      this.form.name = ''
      this.form.estimated_duration = ''
      this.form.customer = null
      this.form.vehicle = null
      this.form.clientComments = null
      this.form.deposit = 50
      this.form.services = [{
        id: null,
        name: '',
        price: 0,
        commission: 0.0,
        estimate: [],
        service_quantity: 1,
        saleItems: []
      }]
      this.selectVehicleStatus = 'UNSELECTED'
      this.showErrors = false
      this.errors = []
      this.success = false
      this.searchCutomer = null
      this.form.requires_evaluation = true
      this.vehicleResults = []
      this.isQuotation = false
      this.isAgreement = false
      this.agreementSelected = null
      this.discountMoney = null
      this.discountPercentage = null
      this.dataVehicle = null
      if (data) {
        this.isOpen = false
        this.$emit('save')
      }
    },
    getEstimateFormatedData () {
      return {
        name: this.form.name,
        estimated_duration: this.form.estimated_duration,
        client_comments: this.form.clientComments,
        state: 'Ingresado',
        requires_evaluation: this.form.requires_evaluation,
        freezes_work_order: true,
        discount: Math.ceil(this.discount),
        agreement: this.agreementSelected,
        deposit: this.form.deposit / 100.0,
        estimate_services: this.form.services.length > 0 && this.form.services[0].service.id !== null ? this.form.services.map(el => {
          return {
            service: el.service.id,
            service_quantity: Number.parseInt(el.service.service_quantity),
            sale_items: el.saleItems.length > 0 && (el.saleItems[0].piece !== null || el.saleItems[0].new_piece_name !== null) ? el.saleItems.map(el => {
              return {
                piece: el.piece?.id,
                new_piece_name: el.new_piece_name,
                quantity: Number.parseInt(el.quantity),
                price: Number(this.getPrice(el))
              }
            }) : [],
          }
        }) : []
      }
    },
    saveToLocalStorage () {
      if (this.form.customer) localStorage.setItem('customerOT', JSON.stringify(this.form.customer))
      if (this.form.vehicle) localStorage.setItem('vehicleOT', JSON.stringify(this.form.vehicle))

    },
    loadFromLocalStorage () {
      console.log('loadFromLocalStorage')
      this.form.customer = JSON.parse(localStorage.getItem('customerOT'))
      this.form.vehicle = JSON.parse(localStorage.getItem('vehicleOT'))

      // this.selectVehicleStatus = 'SELECTED'
    },
    removeDataLocalStorage () {
      localStorage.removeItem('customerOT')
      localStorage.removeItem('vehicleOT')
    },
    goToHistorial () {
      this.saveToLocalStorage()
      if(this.search) {
        localStorage.setItem('selectedPatent', JSON.stringify(this.search))
        localStorage.setItem('selectedVehicle', JSON.stringify(this.form.vehicle))
      } else {
        localStorage.setItem('mySelectedOT', JSON.stringify(this.selectedOT))
      }
      if (this.$route.name !== 'Presupuestos') {
        this.$router.push({ name: 'Presupuestos', params: { vehicleID: this.form.vehicle.id } })
      } else {
        this.isOpen = false
      }
    },
    getPrice(el) {
      if (el.new_piece_name !== '' && el.new_piece_name !== null) return el.unit_price || el.price
      return el.price || el.piece?.price
    },
    async createNewEstimate () {
      this.isSaving = true
      const data = {
        estimate_services: (this.form.services.length > 0 && this.form.services[0].service.id !== null) ? this.form.services.map(el => {
          return {
            service: el.service.id,
            service_quantity: Number.parseInt(el.service.service_quantity),
            sale_items: (el.saleItems.length > 0 && el.saleItems[0].piece !== null) ? el.saleItems.map(el => {
              return {
                piece: el.piece.id,
                quantity: el.quantity,
                price: el.price || el.piece?.price
              }
            }) : [],
          }
        }) : [],
        work_order: this.selectedOT.id,
        client_comments: this.form.clientComments,
        requires_evaluation: this.form.requires_evaluation,
        deposit: 0,
        freezes_work_order: true,
        discount: Math.ceil(this.discount),
        agreement: this.agreementSelected
      }

      const otResponse = await this.createEstimate({ data })
      this.otID = otResponse.data.id

      if (otResponse.status >= 200 && otResponse.status < 300) {
        console.log('+crear nuevo prespuesto boton exito ok..emitiendo esto', otResponse.data)
        this.$emit('save', otResponse.data)
        this.success = true
      }
      this.isSaving = false
    },
    // Validaciones
    cutomerNotNullValidation () {
      if (!this.form.customer) {
        this.errors.push('Debe selecionar un cliente')
      }
    },
    vehicleNotNullValidation () {
      if (!this.form.customer) {
        this.errors.push('Debe selecionar un vehículo')
      }
    },
    unselectedSaleItemValidation () {
      if (this.form.saleItems.filter(el => !el.piece).length) {
        this.errors.push('Algunos items no tienen un producto asociado')
      }
    },
    unselectedServiceValidation () {
      if (this.form.services.filter(el => !el).length) {
        this.errors.push('Algunos servicios no estan selecionados')
      }
    },
    saleItemStockValidation () {
      this.form.services.forEach((s) => {
        if (s.saleItems.filter(el => el.piece).filter(el => el.quantity > el.piece.stock).length) {
          if (!this.errors.includes('Algunos items no tienen stock')) {
            this.errors.push('Algunos items no tienen stock')
          }
        }
      })
    },
    saleItemMinValidation () {
      if (!this.form.services.length) {
        this.errors.push('Debe haber al menos un servicio')
      }
    },
    discountMoneyValidation () {
      if (this.discountMoney !== null && this.discountMoney !== '') {
        if (this.discountMoney > this.subtotalPrice) this.errors.push(`El descuento por dinero no debe ser mayor a $${this.subtotalPrice}`)
        if (this.discountMoney < 0) this.errors.push('El descuento por dinero no debe ser menor a 0')
      }
    },
    discountPercentageValidation () {
      if (this.discountPercentage !== null && this.discountPercentage !== '') {
        if (this.discountPercentage > 100) this.errors.push('El descuento no debe ser mayor a 100%')
        if (this.discountPercentage < 0) this.errors.push('El descuento no debe ser menor a 0%')
      }
    },
    handleClearAgreement () {
      this.isAgreement = false
      this.agreementSelected = null
    },
    handleCreateNewVehicle () {
      this.selectVehicleStatus = 'ON_CREATE'
      this.form.customer = null
    },
    handleCreateNewCustomer () {
      // this.patent = null
      this.selectVehicleStatus = 'ON_CREATE_CUSTOMER'
      this.form.vehicle = null
      this.form.customer = null
      if (!this.dataVehicle) {
        const backupPatent = this.patent
        this.$nextTick(() => {
          this.patent = backupPatent
        })
      }
    },
    async searchPatentInPatenteChile () {
      this.dataVehicle = null
      this.messageSearchPatent = null
      this.searchPatentFinished = false
      if (!this.form.vehicle) {
        this.dataVehicle = null
        console.log('Buscando patente')
        this.loadingSearchPatent = true
        const res = await this.searchPatent({ patente: this.search })
        if (res.status === "success" && this.acceptSearchPatent) {
          // Crea marca y modelo
          const marca = await this.createBrand({ data: { name: res.data.make } })
          const modelo = await this.createVehicleModel({ data: { name: res.data.model, brand: marca.id, year: res.data.year } })
          res.data.modelo = modelo
          res.data.patente = res.data.plate
          this.patent = res.data.patente
          this.dataVehicle = res.data
          this.dataVehicle.engine_number = res.data.engine
          this.dataVehicle.chassis_number = res.data.chassis
          console.log(this.dataVehicle)
          this.loadingSearchPatent = false
          // this.selectVehicleStatus = 'ON_CREATE_CUSTOMER'
          this.searchPatentFinished = true
        } else if (res.status !== "success" && this.acceptSearchPatent) {
          this.messageSearchPatent = res.data
          this.searchPatentFinished = true
          this.dataVehicle = null
          // this.selectVehicleStatus = 'ON_CREATE'
        }
        this.loadingSearchPatent = false
        this.acceptSearchPatent = true
        console.log('Busqueda finalizada')
      }
    },
    cancelSearchPatent () {
      this.dataVehicle = null
      this.acceptSearchPatent = false
      this.loadingSearchPatent = false
    },
    handleOTSuccess () {
      this.isOpen = false;
      this.success = false;
      this.reset()
    },
    handleQuotationCreated (quotationId) {
      this.$emit('quotationCreated', quotationId)
    },
  },
  mounted () {
    if(this.formScheduleOT) {
      this.appointmentData = this.formScheduleOT
      this.search = this.appointmentData.patent
    }
  },
  computed: {
    ...mapGetters('sale', ['sale']),
    ...mapGetters('inventory', ['pieces']),
    ...mapGetters('customer', ['customers']),
    ...mapGetters('vehicle', ['vehicles']),
    patentMaskFormat: () => patentMaskFormat,

    isQuotationPage () {
      return this.$route.path.split('/').pop() === 'quotations'
    },
    totalPrice () {
      return this.subtotalPrice - this.discount
    },
    subtotalPrice () {
      let total = 0
      for (let i = 0; i < this.form.services.length; i++) {
        if (this.form.services[i].saleItems) {
          total += this.form.services[i].saleItems.reduce((acum, el) => acum + el.total_price, 0)
        }
      }
      if (this.form.services.length > 0 && this.form.services[0].service && this.form.services[0].service.service_quantity && this.form.services[0].service.id) {
        total += this.form.services.filter(el => el.service !== null).reduce((acum, el) => acum + (el.service.price * Number.parseInt(el.service.service_quantity)), 0)
      }
      return total
    },
    discount () {
      const subtotal = this.subtotalPrice
      if (!this.form.discount) {
        if ((this.discountMoney !== null && this.discountMoney !== '')) return +this.discountMoney
        if ((this.discountPercentage !== null && this.discountPercentage !== '')) return ((this.discountPercentage * subtotal) / 100)
        return 0
      }
      return this.form.discount ? this.form.discount : 0
    },
    items () {
      return this.vehicleResults.map(vehicle => vehicle)
    },
    serviceIsMissing() {
      return !this.form.requires_evaluation ? (this.form.services.length === 0 || (this.form.services.length > 0 && this.form.services[0].service.id === null)) : false
    },
    rules () {
      return {
        checkMaxPercentage: value => value <= 100 || 'Debe ser menor o igual a 100%',
        checkMin: value => value > 0 || 'Debe ser mayor a 0',
        checkMaxMoney: value => value <= this.subtotalPrice || `Debe ser menor o igual a $${this.subtotalPrice}`
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler (value) {
        this.errors = []
        this.cutomerNotNullValidation()
        this.vehicleNotNullValidation()
        this.discountMoneyValidation()
        this.discountPercentageValidation()
        if (!value.requires_evaluation) {
          // this.unselectedSaleItemValidation()
          this.saleItemStockValidation()
          this.saleItemMinValidation()
          this.unselectedServiceValidation()
        }
      }
    },
    formData: {
      deep: true,
      handler (newData) {
        if (newData) {
          this.form.services = newData.services
          this.form.saleItems = newData.saleItems
          this.form.name = newData.name
          this.form.estimated_duration = newData.estimated_duration
          this.form.discount = newData.discount
          this.form.deposit = newData.deposit * 100
          this.panel = [0]
        }
      }
    },
    'form.services': {
      deep: true,
      handler (newData) {
        if (!this.$refs.evaluationCheckbox) return
        this.$refs.evaluationCheckbox.validate()
      }
    },
    isOpen (newValue) {
      if (!newValue && this.$route.name !== 'Presupuestos') {
        this.removeDataLocalStorage()
      }
      if(!newValue) {
        this.selectVehicleStatus = 'UNSELECTED'
        this.selectCutomerStatus = 'UNSELECTED'
        this.form.vehicle = null
        this.appointmentData = null
        this.reset()
      }
    },
    search (val) {
      if (val === '' || val === null) {
        this.hideData = true
        this.vehicleResults = []
      } else {
        if (this.items.length > 0) return

        if (this.isLoading) return

        this.isLoading = true
        this.hideData = false
        this.listVehicles({ params: { query: val } }).then(
          response => {
            this.vehicleResults = response.data
          }
        )
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      }
    },
    discountMoney () {
      this.errors = []
      this.discountMoneyValidation()
    },
    discountPercentage () {
      this.errors = []
      this.discountPercentageValidation()
    },
    selectVehicleStatus (val) {
      if (val === 'ON_CREATE' && this.search) {
        this.$nextTick(() => {
          this.patent = this.search
        })
      }
    },
    items (val) {
      const myPatent = JSON.parse(localStorage.getItem('selectedPatent'))
      if(this.formScheduleOT || myPatent) {
        this.appointmentData = this.formScheduleOT || myPatent
        // this.search = this.appointmentData.vehicle.patent
        this.onChangeVehicle(val[0])
      }

    }
  },
}
</script>

<style lang="scss" scoped>
.sale-form {
  background-color: white;
}
.total_price {
  font-size: 18px;
  text-align: right;
  margin-top: 0.2rem;
}
.banner {
  background-image: url("../../assets/img/banner.png");
  background-position: right -55px bottom -73px;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(255, 255, 255, 0.589) !important;
  color: #808080 !important
}
.agreement-section {
  background-color: #F9F9F9;
  margin: 5px 0;
  padding: 20px;
}

.prepend-symbol {
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 10px;
  background-color: #F9F9F9 !important;
  border: 1px solid #808080;
  border-right: none;
}
.v-expansion-panel__header {
  margin: 20px !important;
}
// .v-enter-to {
//   opacity: 1;
//   transition: all 1s ease;
// }
.form-selector-btn {
  font-size: 14px !important;
}
</style>
<style lang="css">

#options-content > .v-expansion-panel-content__wrap {
  padding: 0px 0px 0px !important;
}
</style>
