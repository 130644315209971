<template lang="pug">
v-container
  v-card
    div.d-flex
      v-expansion-panels(accordion)
        v-expansion-panel
          v-expansion-panel-header.pl-5
            v-card-title.budgetTitle.pl-1.py-0.pt-1 Trabajos en curso y completados
          v-expansion-panel-content
            div.d-flex.justify-end
              v-btn.justify-end.ma-4(color="primary" @click="goToAdjustment") Ajustar OT +
            v-tabs
              v-tab En curso ({{ jobInProgress.length }})
              v-tab-item
                v-data-table(
                  :headers="InProgressHeaders"
                  hide-default-footer
                  :items="jobInProgress"
                  no-data-text="No hay trabajos en curso"
                )
                template(v-slot:item.date_assigned="{ item }")
                  p.mt-2.mb-1 {{ item.date_assigned | moment('DD/MM/YYYY hh:mm')  || "Aún no ha sido asignado"}}
                template(v-slot:item.assigned_to="{ item }")
                  v-avatar(color="#FFD699" size=32 dark) {{getAvatarText(item.assigned_to)}}
              v-tab Completados ({{ jobFinished.length }})
              v-tab-item
                v-data-table(
                    :headers="CompletedHeaders"
                    hide-default-footer
                    :items="jobFinished"
                    no-data-text="No hay trabajos finalizados"
                  )
                  template(v-slot:item.date_finished="{ item }")
                    p.mt-2.mb-1 {{ item.date_finished | moment('DD/MM/YYYY hh:mm')  || "Aún no ha sido asignado"}}
                  template(v-slot:item.assigned_to="{ item }")
                    v-avatar(color="#FFD699" size=32 dark) {{getAvatarText(item.assigned_to)}}
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      InProgressHeaders: [
        { text: 'Nombre del trabajo', value: 'service_name', sortable: false, align: 'center' },
        { text: 'N° del presupuesto', value: 'estimate', sortable: false, align: 'center' },
        { text: 'Cantidad', value: 'service_quantity', sortable: false, align: 'center' },
        { text: 'Fecha de asignación', value: 'date_finished', sortable: false, align: 'center' },
        { text: 'Asignado a', value: 'assigned_to', sortable: false, align: 'center' },
      ],
      CompletedHeaders: [
        { text: 'Nombre del trabajo', value: 'service_name', sortable: false, align: 'center' },
        { text: 'N° del presupuesto', value: 'estimate', sortable: false, align: 'center' },
        { text: 'Cantidad', value: 'service_quantity', sortable: false, align: 'center' },
        { text: 'Fecha de término', value: 'date_finished', sortable: false, align: 'center' },
        { text: 'Asignado a', value: 'assigned_to', sortable: false, align: 'center' }      ],
      services: []
    }
  },
  computed: {
    ...mapGetters('ot', ['OT', 'jobs']),
    ...mapGetters('user', ['technicians']),
    estimateData () {
      return this.OT ? this.OT.estimate : []
    },
    techniciansData () {
      return this.OT ? this.OT.estimate.technicians : []
    },

    jobFinished () {
      if (!this.jobs) return []
      return this.jobs.filter(job => job.date_finished)
    },
    jobInProgress () {
      if (!this.jobs) return []
      return this.jobs.filter(job => !job.date_finished)
    }
  },
  methods: {
    getTechById (id) {
      return this.technicians.find(el => el.id === id)
    },
    getAvatarText (id) {
      const tech = this.getTechById(id)
      if (tech) {
        return tech.first_name.charAt(0) + tech.last_name.charAt(0)
      }
      return ''
    },
    goToAdjustment () {
      const otID = this.$route.params.otID
      this.$router.push({ name: 'Ajuste de Presupuesto', params: { otID: otID } })
    }
  }
}
</script>
<style lang="scss" scoped>
.budgetTitle {
  color: #3658D3;
}
</style>
<style scoped>
.v-data-table >>> .v-data-table-header {
  background-color: #E4EAFF !important;
  height: 70px;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
