<template lang="pug">
v-container(fluid)
  v-tabs(color="white")
    v-tab.tab-style En diagnóstico ({{ evaluationsCount }})
    v-tab.tab-style Trabajos Diagnosticados ({{ diagnosedCount }})
    v-tab.tab-style En reparación ({{ jobsCount }})
    v-tab.tab-style Trabajos Reparados ({{ repairedCount }})
    v-tab.tab-style Mis trabajos realizados
    v-tab-item
      diagnostic
    v-tab-item
      diagnosed
    v-tab-item
      repair
    v-tab-item
      repaired
    v-tab-item
      HistoricalRepaired
</template>
<script>
import Diagnostic from '@/components/OT/WorkInProgress/Diagnostic.vue'
import Diagnosed from '@/components/OT/WorkInProgress/Diagnosed.vue'
import Repair from '@/components/OT/WorkInProgress/Repair.vue'
import Repaired from '@/components/OT/WorkInProgress/Repaired.vue'
import HistoricalRepaired from '@/components/OT/WorkInProgress/HistoricalRepaired.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    Diagnostic,
    Diagnosed,
    Repair,
    Repaired,
    HistoricalRepaired,
  },
  methods: {
    ...mapActions('ot', ['listEvaluation', 'listJobs', 'listDiagnosed', 'listJobsRepaired']),
  },
  async created() {
    await this.listEvaluation()
    await this.listJobs()
    await this.listDiagnosed()
    await this.listJobsRepaired()
  },
  computed: {
    ...mapGetters('ot', [
      'evaluations',
      'jobs',
      'diagnosed',
      'repaired',
      'evaluationsCount',
      'jobsCount',
      'diagnosedCount',
      'repairedCount',
    ]),
  },
}
</script>
<style lang="scss" scoped>
.v-tab--active {
  background-color: #ffa217 !important;
}
.tab-style {
  border-radius: 4px;
  text-transform: capitalize;
}
</style>
<style>
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(
    .v-slide-group--has-affixes
  )
  .v-slide-group__prev {
  display: none !important;
}
</style>
