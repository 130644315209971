<template lang="pug">
.d-flex.flex-column.fill-height
  alert(ref="alert")

  FiltersCard(:totalByState="totalByState")

  v-container.white.mt-3.flex-grow-1(fluid)
    .d-flex.justify-space-between.mobileColumn
      .d-flex
        v-text-field.mr-5.tabletView(
          v-model="search"
          placeholder="Busca por ID/Cliente/Rut/Patente..."
          outlined dense hide-details style="width: 400px;" height="52px"
        )
        .mobileView
          v-btn.mr-2.mt-2(
            fab elevation="0"
            color="white"
            small
            @click="showTecnicianFilter = !showTecnicianFilter; showActions = false;"
          )
            v-icon mdi-filter-outline
          v-btn.mt-2(
            fab elevation="0"
            small
            @click="showActions = !showActions; showTecnicianFilter = false;"
          )
            v-icon mdi-menu
        v-select.mr-5.desktopView(
          v-model="selectedTech"
          :items="technicians"
          :item-text="(t) => t.first_name + ' ' + t.last_name"
          item-value="id"
          placeholder="Técnico asignado"
          clearable
          outlined dense hide-details style="width: 200px;" height="52px"
        )
      .mobileView.d-flex.flex-column.my-2
        v-select.mr-5(
          v-if="showTecnicianFilter"
          v-model="selectedTech"
          :items="technicians"
          :item-text="(t) => t.first_name + ' ' + t.last_name"
          item-value="id"
          placeholder="Técnico asignado"
          clearable
          outlined dense hide-details style="width: 200px;" height="52px"
        )
        v-btn.d-flex.justify-start(v-if="showActions" x-large depressed dark color="#4C7AEC" @click="OpenModalExport()" text)
          v-icon.mr-2 mdi-microsoft-excel
            span Exportar
        v-btn.mt-2.d-flex.justify-start(v-if="showActions" x-large depressed dark color="#4C7AEC" @click="openModalExpenses(true)" text) + Gasto

      .d-flex
        v-btn.mr-5.desktopView(x-large depressed dark color="#4C7AEC" @click="OpenModalExport()" outlined)
          v-icon.mr-2 mdi-microsoft-excel
          span Exportar
        v-btn.mr-5.desktopView(x-large depressed dark color="#4C7AEC" @click="openModalExpenses(true)" outlined) + Gasto

        //- Orden de Trabajo
        v-btn.desktopView.desktopBtn(x-large depressed dark color="#4C7AEC" @click="$refs.OTForm.open()") Orden de Trabajo
        v-btn.mobileView(x-large fab fixed bottom right dark color="#4C7AEC" @click="$refs.OTForm.open()")
          v-icon mdi-plus
        v-btn.tabletBtn(x-large rounded fixed bottom right dark color="#4C7AEC" @click="$refs.OTForm.open()") + Orden de Trabajo

    FilterChips

    v-data-table.mt-auto(
      :headers="table.headers"
      :items="OTs"
      hide-default-footer
      multi-sort
      :server-items-length="rowCount"
      :loading="table.loading"
      loading-text="Cargando órdenes de trabajo..."
      :options.sync="table.options"
    )
      template(v-slot:item.id="{ item }")
        div
          strong # {{item.id}}
        OTListEstimateSummary(:count="item.estimate.length" :OT="item" :key="item.id" @save="fetchData")
      template(v-slot:item.actions="{ item }")
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-btn(icon @click="$router.push({ name: 'Detalle OT', params: {otID: item.id }})" large v-bind="attrs" v-on="on")
              v-icon(style="color: #8E8E8E") mdi-eye-outline
          span Detalles OT
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-btn(icon @click="handleOpenSelectDialog(item.id)" large v-show="item.state === 'Diagnosticado'" v-bind="attrs" v-on="on")
              v-icon(style="color: #FF897F") mdi-square-edit-outline
          span Completar Presupuesto
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-btn(icon v-show="item.state === 'Presupuestado'" @click="openModalApprove(item.id)" x-large v-bind="attrs" v-on="on")
              v-icon(style="color: #2DA71A") mdi-cash-check
          span Aprobar Presupuesto
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-btn(icon large v-show="item.state === 'Reparado' && (user && user.groups.includes('Supervisor'))" @click="openModalApproveWork(item.id)" v-bind="attrs" v-on="on")
              v-icon(style="color: #462DFA") mdi-check-circle-outline
          span Aprobar Trabajo
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-btn(icon large v-show="showPendingPaymentButton(item)" @click="openModalClosePayment(item.id)" v-bind="attrs" v-on="on")
              v-icon(style="color:#FF4CD6") mdi-account-cash
          span Saldo Pendiente
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-btn(icon large v-show="item.state === 'Listo' && item.pending_payment <= 0" @click="openModalVehicleDelivery(item)" v-bind="attrs" v-on="on")
              v-icon(style="color:#7A70E2") mdi-car-side
          span Entrega Vehículo
      template(v-slot:item.technicians="{ item }")
        v-row
          div.mr-3
            OTEvaluationTechForm(:OTId="item.id" :techId="item.evaluation_tech" :state="item.state" :available="item.state === 'Ingresado' || item.state === 'En diagnóstico' || item.state ==='Borrador'" @save="fetchData" @showAlert="successAction")
          div
            OTServiceTechForm(v-if="checkEstimatesState(item)" :services="services" :ot="item" @save="successAction" :available="item.state === 'Entregado'")
      template(v-slot:item.created_at="{ item }")
        div {{item.created_at.slice(0,10)}}
      template(v-slot:item.state="{ item }")
        .d-flex.align-center
          div
            v-chip.flex-grow-0.flex-shrink-1(label :text-color="getStatusTextColor(item.state)" :color="getStatusColor(item.state)") {{ item.state }}
  v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)

  OTForm(ref="OTForm" @save="fetchData")
  ApproveBudget(ref="ApproveBudget" :otID="otID" :budgets="budgets" @showAlert="successAction")
  ApproveWork(ref="ApproveWork" :otID="otID" @reloadData="successAction")
  OTDiagnosticReceptionistForm(ref="diagnosticReceptionistForm" @save="successAction")
  ClosePayment(ref="ClosePayment" :otID="otID" :budgets="budgets" @showAlert="successAction")
  VehicleDelivery(ref="VehicleDelivery" :ot="selectedOt"  @showAlert="successAction" @success="fetchData")
  ModalExpenses(ref="ModalExpenses" @refreshData="successAction" :isExpense="isExpense")
  ModalExport(ref="ModalExport")

  v-dialog(v-model="isOpen" transition="dialog-top-transition" max-width="600")
    template(v-slot:default="dialog")
      v-card
        v-toolbar(color="#4C7AEC" dark) Seleccione el Presupuesto
        v-container
          v-select(:items="estimates" label="Presupuestos" outlined v-model="idBudgetSelected")
        v-card-actions.justify-end
          v-btn.mr-5(@click="handleOpenBudget()" color="#4C7AEC" dark :disabled="idBudgetSelected === null") Abrir
          v-btn(@click="isOpen = false") Cerrar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { io } from 'socket.io-client'
import settings from '@/../settings'
import OTForm from '../sale/OTForm.vue'
import OTEvaluationTechForm from './subcomponent/OTEvaluationTechForm.vue'
import OTServiceTechForm from './subcomponent/OTServiceTechForm.vue'
import OTListEstimateSummary from './estimate/OTListEstimateSummary'
import ApproveBudget from './ApproveBudget.vue'
import ApproveWork from './ApproveWork.vue'
import alert from '../shared/alert.vue'
import OTDiagnosticReceptionistForm from './OTDiagnosticReceptionistForm'
import StateColorMixin from '../mixins/StateColorMixin.vue'
import otStatusColors from '../../shared/otStatusColors'
import ClosePayment from './ClosePayment.vue'
import VehicleDelivery from './VehicleDelivery.vue'
import ModalExpenses from '../finance/ModalExpenses.vue'
import ModalExport from './ModalExport.vue'
import FiltersCard from './subcomponent/FiltersCard.vue'
import FilterChips from './subcomponent/FilterChips.vue'

export default {
  components: {
    OTForm,
    OTEvaluationTechForm,
    OTServiceTechForm,
    OTListEstimateSummary,
    ApproveBudget,
    alert,
    OTDiagnosticReceptionistForm,
    StateColorMixin,
    ApproveWork,
    ClosePayment,
    VehicleDelivery,
    ModalExpenses,
    ModalExport,
    FiltersCard,
    FilterChips,
  },
  data() {
    return {
      isExpense: false,
      selectedOt: {},
      idBudgetSelected: null,
      estimates: [],
      isOpen: false,
      otID: null,
      budgets: [],
      search: null,
      page: 1,
      rowCount: 0,
      totalByState: {},
      selectedTech: null,
      showTecnicianFilter: false,
      showActions: false,
      table: {
        loading: false,
        options: {
          sortBy: ['state', 'created_at'],
          sortDesc: [false, true],
        },
        headers: [
          { text: 'N', value: 'id', width: '150', fieldName: 'id' },
          {

            text: 'Estado',
            value: 'state',
            width: '150',
            sort: this.sortState,
            fieldName: 'state',
          },
          {
            text: 'Fecha',
            value: 'created_at',
            width: '120',
            fieldName: 'created_at',
          },
          {
            text: 'Cliente',
            value: 'customer_data.full_name',
            width: '180',
            fieldName: 'customer__name',
          },
          {
            text: 'Vehículo',
            value: 'vehicle_data.model_display',
            width: '200',
            fieldName: 'vehicle__model__name',
          },
          {
            text: 'Patente',
            value: 'vehicle_data.patent',
            width: '100',
            fieldName: 'vehicle__patent',
          },
          { text: 'Asignado a', value: 'technicians', sortable: false },
          {
            text: 'Acciones',
            value: 'actions',
            width: '150',
            cellClass: 'px-0',
            sortable: false,
          },
        ],
      },
      services: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('ot', ['OTs']),
    ...mapGetters('user', ['technicians']),
    ...mapFields('OTListFilterStore', ['selectedFilters']),

    pageCount() {
      return Math.ceil(this.rowCount / 10)
    },

    orderingParam() {
      const { getOrderingField } = this
      const { sortBy, sortDesc } = this.table.options
      if (sortBy.length === 0) return {}
      const ordering = sortBy
        .map((s, i) => `${sortDesc[i] ? '-' : ''}${getOrderingField(s)}`)
        .join(',')
      return { ordering }
    },

    filterParam() {
      // return 'state__in=1,2,3' with selectedFilters values
      const { selectedFilters } = this
      const values = selectedFilters
        .map((f) => f.states.join(','))
        .join(',')
      return values ? { state__in: values } : {}
    },

    techParam() {
      const { selectedTech } = this
      return selectedTech ? { technician: selectedTech } : {}
    },
  },
  methods: {
    ...mapActions('customer', ['listCustomers']),
    ...mapActions('estimate', ['listEstimates']),
    ...mapActions('inventory', ['listPieces']),
    ...mapActions('ot', [
      'listOT',
      'setOTEvaluation',
      'listBudgets',
      'servicesForAproval',
      'approvedPayments',
    ]),
    ...mapActions('service', ['listServicesInWorkOrder']),
    ...mapActions('user', ['listTechnicians']),

    getOrderingField(value) {
      return this.table.headers.find((h) => h.value === value).fieldName
    },

    showPendingPaymentButton(item) {
      if (item.state === 'Diagnosticado' || item.state === 'Presupuestado' || item.state === 'Ingresado') {
        return
      }
      return (
        (this.checkPendingPaymentStatus(item.estimate) ||
        item.state === 'Listo' ||
        item.state === 'Presupuesto aprobado' ||
        item.state === 'En reparación' ||
        item.state === 'Reparado') &&
        item.pending_payment > 0 &&
        this.user &&
        this.user.groups.includes('Recepcionista')
      )
      
    },
    openSaleDetail(sale) {
      this.$refs.saleDetail.open(sale)
    },
    getServiceNames(item) {
      if (item.services_data.length) {
        return item.services_data.map((el) => el.name).join(', ')
      }
    },
    getStatusColor(state) {
      return otStatusColors[state]
        ? otStatusColors[state].color
        : otStatusColors.default.color
    },
    getStatusTextColor(state) {
      return otStatusColors[state]
        ? otStatusColors[state].textColor
        : otStatusColors.default.textColor
    },

    async fetchData({ inBackground } = {}) {
      if (this.table.loading) return
      this.table.loading = true
      if (inBackground) this.table.loading = true

      const params = {
        page: this.page,
        search: this.search,
        ...this.orderingParam,
        ...this.filterParam,
        ...this.techParam,
      }
      const response = await this.listOT({ params })

      this.listCustomers()
      this.listPieces()
      this.services = []

      if (response.status >= 200 && response.status < 300) {
        this.rowCount = response.data.count
        this.totalByState = response.data.totals_by_state

        this.loadWorkOrderServices(response.data.results)
      }
      this.table.loading = false
    },

    async loadWorkOrderServices(ots) {
      ots.forEach((ot) => {
        const estimates = ot.estimate.filter(
          (e) => e.state !== 'Ingresado' && e.state !== 'En diagnóstico' && e.state !== 'Diagnosticado' && e.state !== 'Presupuestado'
        )
        if (estimates.length > 0) {
          this.listServicesInWorkOrder({ otID: ot.id }).then((response) => {
            if (response.status === 200) {
              this.services.push({
                otId: ot.id,
                services: response.data.filter((service) =>
                  estimates.some((e) => e.id === service.estimate)
                ),
              })
            }
          })
        }
      })
    },

    async openModalApprove(id) {
      this.otID = id
      await this.listBudgets(id).then((response) => {
        if (response.status === 200) {
          this.budgets = response.data === '' ? [] : response.data
          this.$refs.alert.handleShowAlert('success', '', false, '')
          this.$refs.ApproveBudget.open()
        } else {
          this.$refs.alert.handleShowAlert(
            'error',
            'No hay presupuestos para mostrar.',
            true,
            'red'
          )
        }
      })
    },
    successAction(data) {
      this.fetchData()
      this.$refs.alert.handleShowAlert(
        data.type,
        data.message,
        data.show,
        data.color
      )
    },
    handleOpenBudget() {
      this.isOpen = false
      this.$refs.diagnosticReceptionistForm.open(this.idBudgetSelected)
    },
    handleOpenSelectDialog(otId) {
      this.idBudgetSelected = null
      this.listEstimates({ params: { work_order: otId } }).then((response) => {
        const estimatesData = response.data.results.filter(
          (e) => e.state === 'Diagnosticado'
        )
        this.idBudgetSelected =
          estimatesData.length === 1 ? estimatesData[0].id : null
        this.estimates = estimatesData.map((estimate) => {
          if (estimate.state === 'Diagnosticado') {
            return {
              text:
                estimate.name !== null && estimate.name !== ''
                  ? `N° ${estimate.id} - ${estimate.name}`
                  : `Presupuesto N° ${estimate.id}`,
              value: estimate.id,
            }
          }
        })
        this.isOpen = true
      })
    },
    async openModalApproveWork(id) {
      this.otID = id
      await this.servicesForAproval({ otID: id }).then((response) => {
        if (response.status === 200) {
          this.$refs.ApproveWork.open()
        }
      })
    },
    openModalClosePayment(id) {
      this.otID = id
      this.approvedPayments({ otID: id }).then((response) => {
        this.budgets =
          response.data === '' || response.status === 204 ? [] : response.data
        this.$refs.alert.handleShowAlert('success', '', false, '')
        this.$refs.ClosePayment.open()
      })
    },
    openModalVehicleDelivery(ot) {
      this.selectedOt = ot
      this.$refs.VehicleDelivery.open()
    },
    openModalExpenses(item) {
      this.isExpense = item
      this.$refs.ModalExpenses.open(item)
    },
    OpenModalExport() {
      this.$refs.ModalExport.open()
    },
    checkEstimatesState(ot) {
      return ot.estimate.some(
        // (e) => e.state === 'Presupuesto aprobado' || e.state === 'En reparación'
        (e) => e.state !== 'Ingresado' && e.state !== 'En diagnóstico' && e.state !== 'Diagnosticado' && e.state !== 'Presupuestado'
      )
    },
    checkPendingPaymentStatus(estimates) {
      return estimates.some(
        (e) =>
          e.state === 'Presupuesto aprobado' ||
          e.state === 'En reparación' ||
          e.state === 'Reparado' ||
          e.state === 'Listo'
      )
    },
    initWebsockets() {
      this.socket = io(settings.webSocketsUrl)

      this.socket.on('work_order:created', () => {
        this.fetchData({ inBackground: true })
      })

      this.socket.on('work_order:updated', () => {
        this.fetchData({ inBackground: true })
      })
    },
  },
  mounted () {
    // const search = JSON.parse(localStorage.getItem('selectedPatent'))
    // if(search) {
    //   this.$refs.OTForm.open()
    //   setTimeout(() => {
    //     localStorage.removeItem('selectedVehicle')
    //     localStorage.removeItem('selectedPatent')
    //     localStorage.removeItem('back')
    //   }, 3000);

    // }
  },
  watch: {
    page() {
      this.fetchData()
    },
    search() {
      this.page = 1
      this.fetchData()
    },
    selectedFilters: {
      handler() {
        this.page = 1
        this.fetchData()
      },
      deep: true,
    },
    techParam() {
      this.page = 1
      this.fetchData()
    },
    'table.options': {
      handler(val, oldval) {
        this.fetchData()
      },
      deep: true,
    },
  },
  async created() {
    this.listTechnicians()
    this.fetchData()
    this.initWebsockets()
  },
  destroyed() {
    if (!this.socket) return
    this.socket.disconnect()
  },
}
</script>

<style lang="scss" scoped>
td {
  border: 1px black solid !important;
}

::v-deep .v-select__slot .v-input__append-inner {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  align-self: auto !important;
}
.mobileColumn {
  @media (max-width: 680px) {
    flex-direction: column;
  }
}
.mobileView {
  @media (min-width: 768px) {
    display: none !important;
  }
  @media (max-width: 767px) {
    display: flex !important;
  }
}
.desktopView {
  @media (min-width: 768px) {
    display: block !important;
  }
  @media (max-width: 767px) {
    display: none !important;
  }
}
.tabletView {
  width: 200px !important;
}
.tabletBtn {
  @media (min-width: 768px) and (max-width: 1024px) {
    display: block !important;
  }
  @media (min-width: 1025px) {
    display: none !important;
  }
  @media(max-width: 767px) {
    display: none !important;
  }
}
.desktopBtn {
  @media (min-width: 768px) and (max-width: 1024px) {
    display: none !important;
  }
}
</style>
