<template lang="pug">
v-container
  OTForm(ref="OTForm" @save="handleSave" :selectedOT="selectedOt")
  v-card
    v-expansion-panels(accordion)
      v-expansion-panel
        v-expansion-panel-header
          v-card-title.budgetTitle.pl-1.py-0.pt-1 Presupuestos
            v-btn.ml-auto.addNewBudget.showDesktop(:disabled="selectedOt.state === 'Entregado'" @click="$refs.OTForm.open(selectedOt)") + Agregar Nuevo
            v-btn.ml-auto.addNewBudget.showMobile(:disabled="selectedOt.state === 'Entregado'" small @click="$refs.OTForm.open(selectedOt)")
              v-icon mdi-plus
        v-expansion-panel-content
          v-data-table.budget-table.mb-5(
            :headers="tableBudget.headers"
            hide-default-footer
            :items="estimateData"
            :single-expand="false"
            :expanded.sync="expanded"
            show-expand
            item-key="id"
          )
            template(v-slot:item.name="{ headers, item }")
              p.mt-2.mb-1 {{ item.name !== null && item.name !== '' ? `N° ${item.id} - ${item.name}` : `Presupuesto N° ${ item.id }` }}
            template(v-slot:item.status="{ item }")
              v-chip(label :color="getStatusColor(item.state)" :text-color="getStatusTextColor(item.state)") {{ item.state }}
            template(v-slot:item.price="{ item }")
              p.mt-2.mb-1 {{ sumBudgetsPrices(item) | currency }}
            template(v-slot:item.detail="{ item }")
              v-btn(
                color="primary" outlined dense
                :loading="loadingPDFButton"
                @click.native.stop="handleDownloadPdf(item.id)"
              ) Descargar
                v-icon.ml-2 mdi-file-pdf-box
            template(v-slot:expanded-item="{ headers, item }")
              td.py-5.px-1(:colspan="headers.length")
                v-row
                  v-col(xs="12" sm="12" md="9" lg="9" xl="9")
                    v-data-table(
                      dense
                      :headers="tableServices.headers"
                      hide-default-footer
                      :items="item.estimate_services"
                      :single-expand="false"
                      :expanded.sync="expandedServices"
                      show-expand
                      item-key="service_data.name"
                    )
                      template(v-slot:item.name="{ headers, item }")
                        p.mt-2.mb-1 {{ item.service_data.service_code || '' }} {{item.service_data.name}}
                      template(v-slot:item.quantity="{ item }")
                        p.mt-2.mb-1 x{{ item.service_quantity }}
                      template(v-slot:item.price="{ item }")
                        p.mt-2.mb-1 {{ item.service_data.price | currency }}
                      template(v-slot:expanded-item="{ headers, item }")
                        td.py-5.px-1(:colspan="headers.length")
                          v-row
                            v-col(cols="12")
                              v-data-table.elevation-0(
                                dense
                                :headers="tableSaleItems.headers"
                                hide-default-footer
                                :items="item.sale_items_data"
                                no-data-text="No existen repuestos"
                                )
                                template(v-slot:item.model="{ item }")
                                  p.itemStyle(v-if="!item.piece") {{ item.new_piece_name }}
                                  p.itemStyle(v-else="!item.piece.brand_name") {{ item.piece.code || ''}} {{ item.piece.name }}
                                  p.itemStyle(v-else) {{ item.piece.code || ''}} {{ item.piece.name }} - {{ item.piece.brand_name }}
                                template(v-slot:item.quantity="{ item }")
                                  p.itemStyle x{{item.quantity }}
                                template(v-slot:item.price="{ item }")
                                  p.itemStyle {{ (item.price ? item.price || item.piece.price : item.unit_price) | currency }}
                  v-col.showDesktop(cols="3")
                    div.d-flex.flex-column.justify-end.align-end.mr-5.subtotal-budget.text-right
                      strong.subtotal-color Total Repuestos: {{ sumItemsPrices(item.estimate_services) | currency }}
                      strong.subtotal-color Total Servicios: {{ sumServicesPrices(item.estimate_services) | currency }}
                v-row.showMobile
                  v-col(cols="12")
                    div.d-flex.flex-column.justify-end.align-end.mr-5.subtotal-budget
                      strong.subtotal-color Total Repuestos: {{ sumItemsPrices(item.estimate_services) | currency }}
                      strong.subtotal-color Total Servicios: {{ sumServicesPrices(item.estimate_services) | currency }}
                v-row
                  v-col(cols="12")
                    .d-flex.flex-column.justify-end.align-end.mr-5.subtotal-items
                      strong.subtotal-color Subtotal:  {{ (sumItemsPrices(item.estimate_services)) + (sumServicesPrices(item.estimate_services)) | currency }}
                      strong.subtotal-color(v-if="item.discount") Descuento: - {{ item.discount | currency }}
                      h3 Total Presupuesto:  {{ sumBudgetsPrices(item) | currency}}

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import otStatusColors from '../../../shared/otStatusColors'
import OTForm from '../../sale/OTForm.vue'
export default {
  props: {
    selectedOt: {
      type: Object
    }
  },
  components: {
    OTForm
  },
  data () {
    return {
      tableBudget: {
        headers: [
          { text: 'Prespuesto', value: 'name', width: '200px', class: "budgetsHeaders", filterable: false, sortable: false},
          { text: 'Estado', value: 'status', width: '100px', align: 'center', class: "budgetsHeaders", filterable: false, sortable: false},
          { text: 'Precio por Servicio', value: 'price', width: '150px', class: "budgetsHeaders", filterable: false, sortable: false},
          { text: 'Detalle', value: 'detail', class: "budgetsHeaders", filterable: false, sortable: false},
          { text: '', value: 'data-table-expand'},
        ]
      },

      tableServices: {
        headers: [
          { text: 'Servicios a realizar', value: 'name', width: '150px', filterable: false, sortable: false},
          { text: 'Cantidad', value: 'quantity', width: '100px', align: 'center', filterable: false, sortable: false},
          { text: 'Precio por Servicio', value: 'price', width: '100px', filterable: false, sortable: false},
          { text: '', value: 'data-table-expand' },
        ]
      },
      tableSaleItems: {
        headers: [
          { text: 'Repuesto', value: 'model', width: '150px', class: 'itemStyle', filterable: false, sortable: false },
          { text: 'Cantidad', value: 'quantity', width: '100px', align: 'center', class: 'itemStyle', filterable: false, sortable: false },
          { text: 'Precio Unitario', value: 'price', width: '100px', class: 'itemStyle', filterable: false, sortable: false }
        ]
      },
      loadingPDFButton: false,
      expanded: [],
      expandedServices: []
    }
  },
  methods: {
    ...mapActions('inventory', ['downloadPdf']),
    getStatusColor (state) {
      return otStatusColors[state] ? otStatusColors[state].color : otStatusColors.default.color
    },
    getStatusTextColor (state) {
      return otStatusColors[state] ? otStatusColors[state].textColor : otStatusColors.default.textColor
    },
    sumServicesPrices (services) {
      const initialValue = 0
      const total = services.map(s => {
        return s.service_data.price * s.service_quantity
      })
      return total.reduce((acc, curr) => { return acc + curr }, initialValue)
    },
    sumItemsPrices (estimateServices) {
      const total = 0
      return estimateServices.reduce((acc, curr) => {
        return acc + curr.sale_items_data.reduce((acc2, curr2) => {
          return acc2 + (curr2.price ? curr2.price : curr2.unit_price) * curr2.quantity
        }, total)
      }, total)
    },
    sumBudgetsPrices (budget) {
      return (this.sumServicesPrices(budget.estimate_services) + this.sumItemsPrices(budget.estimate_services)) - budget.discount
    },
    handleSave (OT) {
      // console.log('evento recibido @save desde OTform con esta data', OT)
      this.$emit('save', OT?.work_order)
    },
    async handleDownloadPdf (id) {
      this.loadingPDFButton = true
      const res = await this.downloadPdf({ id })
      const blob = new Blob([res.data], {
        type: {
          type: res.headers['content-type']
        }
      })
      const blobUrl = window.URL.createObjectURL(blob)
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = blobUrl
      tempLink.setAttribute('download', `Presupuesto - ${id}.pdf`)
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      this.loadingPDFButton = false
    },

    backFromBudgets (selectedOt) {
      this.$refs.OTForm.open(selectedOt)
    }
  },
  computed: {
    ...mapGetters('ot', ['OT']),
    ...mapGetters('service', ['services']),
    estimateData () {
      return this.OT ? this.OT.estimate : []
    }
  },

  mounted () {
    const search = JSON.parse(localStorage.getItem('selectedPatent'))
    const myOT = JSON.parse(localStorage.getItem('mySelectedOT'))
    if(myOT) {
      this.backFromBudgets(myOT)
    }else if(search) {
      this.backFromBudgets()
    }

    localStorage.removeItem('back', false)
    localStorage.removeItem('selectedPatent', false)
    localStorage.removeItem('mySelectedOT')
  }
}
</script>
<style lang="scss" scoped>
.showDesktop {
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
}
.showMobile {
  display: block;
  @media (min-width: 1024px) {
    display: none;
  }
}
.budgetTitle {
  color: #3658D3;
}
.budgetsHeaders {
  background-color: #E4EAFF !important;
  color: #969696 !important;
}

.headers-desktop {
  display: flex;
  width: 100%;
  @media (width >= 320px) and (width <= 599px) {
    display: none;
  }
}
.headers-mobile {
  display: none;
  @media (width >= 320px) and (width <= 599px) {
    display: block;
  }
}
.addNewBudget {
  background-color: #E4EAFF !important;
  color: #3658D3;

}
.subtotal-budget, .subtotal-items  {
  height: 100%;
}
.subtotal-color {
  color: #969696;
}
.budget-table::v-deep .v-data-table-header {
  @media (width >= 600px) {
    background-color: #E4EAFF !important;
    color: #969696 !important;
  }
}
.v-data-table::v-deep th {
  font-size: 16px !important;
  font-weight: 500;
}
.v-data-table::v-deep td {
  font-size: 16px !important;
}
.v-data-table::v-deep .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-style: italic;
  padding: 0px;
  padding-left: 20px;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
<style>
.itemStyle {
  color: #969696 !important;
}
</style>

