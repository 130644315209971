<template lang="pug">
SideModal(v-model="isOpenApproveBudget" max-width="800")
  v-container(fluid)
    alert(ref="alert")
    v-btn.close-btn(fab color="white" small depressed @click="resetModal()")
      v-icon mdi-close-thick
  v-card
    v-card-title Aprobar Presupuesto
    v-spacer
    v-card-subtitle Presupuesto: OT {{ otID }}
    v-spacer
    v-expansion-panels(accordion)
      v-expansion-panel(v-for="(budget, index) in budgets" :key="index")
        v-expansion-panel-header.pl-5
          v-row
            v-col.d-flex.justify-space-between(cols="11")
              v-data-table(:headers="table.headers" :items="[localBudgets[index]]" hide-default-footer)
                template(v-slot:item.budget_select="{ item }")
                  v-checkbox(@change="selectBudget(item)" :style="item.style" :input-value="item.checked")
                    template(#label)
                      p.mb-0.text-no-wrap {{ item.name !== null && item.name !== '' ? `N° ${item.id} - ${item.name}` : `Presupuesto ${item.id}` }}
                        v-icon.ml-1(v-if="item.agreement !== null" style="color: #D78237;") mdi-alpha-c-circle
                template(v-slot:item.total_budget="{ item }")
                    p.mb-0.text-no-wrap(:style="item.style") {{ item.total | currency }}
                template(v-slot:item.percentage_payment="{ item }")
                  v-text-field.percentage-input(type="number" max="100" suffix="%" v-model="item.percentage"
                  @input="calculateTotalBudget(item.total, item.percentage, item)" :disabled="!item.checked || item.disablePercentageInput" :style="item.style")
                template(v-slot:item.total_payment="{ item }")
                  div(:style="item.style") {{ item.budgetPayment | currency }}

        v-expansion-panel-content
          v-row.ma-0.pa-0
            v-col(cols="12")
              v-data-table(
                dense
                :headers="tableBudget.headers"
                hide-default-footer
                :items="budget.estimate_services"
                :single-expand="false"
                :expanded.sync="expanded"
                show-expand
                item-key="service_data.name"
              )
                template(v-slot:item.name="{ headers, item }")
                  p.mt-2.mb-1 {{ item.service_data.service_code || '' }} {{item.service_data.name}}
                template(v-slot:item.quantity="{ item }")
                  p.mt-2.mb-1 x{{ item.service_quantity }}
                template(v-slot:item.price="{ item }")
                  p.mt-2.mb-1 {{ item.service_data.price | currency }}
                template(v-slot:expanded-item="{ headers, item }")
                  td.py-2(:colspan="headers.length")
                    v-data-table.elevation-0(
                      dense
                      :headers="tableSaleItems.headers"
                      hide-default-footer
                      :items="item.sale_items_data"
                      no-data-text="No existen repuestos"
                      )
                      template(v-slot:item.model="{ item }")
                        p.itemStyle(v-if="!item.piece") {{ item.new_piece_name }}
                        p.itemStyle(v-else="!item.piece.brand_name") {{ item.piece.code || ''}} {{ item.piece.name }}
                        p.itemStyle(v-else) {{ item.piece.code || ''}} {{ item.piece.name }} - {{ item.piece.brand_name }}
                      template(v-slot:item.quantity="{ item }")
                        p.itemStyle x{{item.quantity }}
                      template(v-slot:item.price="{ item }")
                        p.itemStyle {{ (item.price ? item.price || item.piece.price : item.unit_price) | currency }}
            v-col(cols="12")
              div.d-flex.flex-column.justify-end.align-end.mr-5.subtotal-budget
                strong.subtotal-color Total Repuestos: {{ sumItemsPrices(budget.estimate_services) | currency }}
                strong.subtotal-color Total Servicios: {{ sumServicesPrices(budget.estimate_services) | currency }}
          v-row
            v-col(cols="12")
              .d-flex.flex-column.justify-end.align-end.mr-5.subtotal-items
                strong.subtotal-color Subtotal: {{ sumItemsPrices(budget.estimate_services) + sumServicesPrices(budget.estimate_services) | currency }}
                strong.subtotal-color(v-if="budget.discount") Descuento: - {{ budget.discount | currency }}
                h3 Total Presupuesto: {{ sumBudgetsPrices(budget) | currency}}
    p.mt-4.pl-3.text-grey.mb-0 Método de Pago:
    v-radio-group.pl-3.payment-methods(v-model="paymentMethod" row @change="resetPaymentFields")
      v-radio(label="Transbank" value="Transbank")
      v-radio(label="Efectivo" value="Efectivo")
      v-radio(label="A Crédito" value="A Crédito")
      v-radio(label="Transferencia" value="Transferencia")
      v-radio(label="Débito" value="Débito")
      v-radio(label="Cheque" value="Cheque")

    div(v-if="paymentMethod === 'A Crédito'")
      p.color.mr-5.pl-5 Fecha de pago
      v-menu(ref="menu2" v-model="menu2" :close-close-on-content-click="false" transitions="scale-transition" offset-y min-width="auto")
        template(v-slot:activator="{ on, attrs }")
          v-text-field.ml-5.mt-3(
            ref="credit_term"
            v-model="credit_term.split('-').reverse().join('/')"
            prepend-inner-icon="mdi-calendar"
            solo dense flat
            v-bind="attrs"
            v-on="on"
            style="max-width: 40%; margin-top: 12px;"
            placeholder=" Ingrese una fecha"
            readonly clearable outlined
            :rules="[v => !!v || 'La fecha de pago es obligatoria']"
          )
        v-date-picker(v-model="credit_term" no-title locale="es-CL")

    div(v-if="paymentMethod === 'Cheque'" style="display: flex; flex-direction: column")
      div.float-end.pb-3.pr-2
        v-btn.float-end(color="primary" dense @click="newCheck" :disabled="disabledNewCheck"  ) + Nuevo cheque
      div(v-if="paymentMethod === 'Cheque'")
        v-data-table.pb-8(:headers="tableCheck.headers" :items="allChecks" density="compact" item-key="" hide-default-footer)
          template(v-slot:item.check_number="{ item }")
            v-text-field.mt-2(
              ref="check_number"
              outlined dense
              v-model="item.check_number"
              placeholder="N° Cheque"
              :rules="[v => v * 1 !== 0 || 'Debe ser mayor a 0', rules.required, rules.isNumber]"
              :disabled="item.disabled"
            )
          template(v-slot:item.check_date="{ item }")

            v-menu(ref="menu" v-model="item.menu" :close-close-on-content-click="false" transitions="scale-transition" offset-y min-width="auto")
              template(v-slot:activator="{ on, attrs }")
                v-text-field.mt-2(
                  ref="check_date"
                  v-model="item.check_date.split('-').reverse().join('/')"
                  outlined dense
                  v-bind="attrs"
                  v-on="on"
                  placeholder="Fecha"
                  readonly
                  :rules="[v => !!v || 'La fecha es obligatoria']"
                  :disabled="item.disabled"
                )
              v-date-picker(v-model="item.check_date" no-title locale="es-CL")

          template(v-slot:item.check_amount="{ item }")
            v-text-field.mt-2(
              ref="check_amount"
              outlined dense
              v-model="item.check_amount"
              prefix="$"
              :rules="[rules.required, rules.greaterThanZero]"
              :disabled="item.disabled"
              v-maska:[moneyMaskFormat]
            )
          template(v-slot:item.actions="{ item }")
            div(style="display: flex; margin-top: -20px !important")
              v-tooltip(bottom)
                template(v-slot:activator="{ on, attrs }")
                  v-btn(icon @click="clearCheck(item)" v-bind="attrs" v-on="on")
                    v-icon mdi-broom
                span Limpiar
              v-tooltip(bottom v-if="item.allowDelete")
                template(v-slot:activator="{ on, attrs }")
                  v-btn(icon v-bind="attrs" v-on="on" @click="deleteCheck(item)")
                    v-icon mdi-trash-can
                span Eliminar

    v-row
      v-col.d-flex(cols="3")
        v-text-field.pl-3.pt-5.reference-input(label="Referencia/ID:" v-model="payment_id")
      v-col.d-flex(cols="6")
        v-textarea.px-3(outlined label="Comentarios:" clearable clear-icon="mdi-close-circle"
    counter :rules="maxLength" :value="comment" rows="2" row-heigth="20" v-model="comment")
    div.detail
      span(v-for="budget in budgetsSelected" :key="budget.id")
        p.text-modal.pl-3.my-1 Presupuesto {{ budget.id }} (abono {{ budget.percentage }}%):
          span.text-black.ml-2 {{ budget.budgetPayment | currency }}
      p.pl-3.pt-2.text-black(:class="conditionalHighlight()")  Total a pagar abono:
        span.text-black(:class="conditionalHighlight()")  {{ totalBudgetToPay | currency }}
      v-row.pl-3
        v-col(cols="4")
          a(@click="() => paymentMethod !== 'Cheque'? showInputPay=!showInputPay : ''") {{ paymentMethod === 'Cheque' ? 'Monto total en cheques' : 'Otro Monto' }}
        v-col(col="8")
          v-text-field.text-input.text-black(
            v-if="showInputPay"
            ref="otherAmountField"
            label="Ingrese Monto"
            hide-details="auto"
            v-model="payOtherAmount"
            prefix="$"
            :readonly="paymentMethod === 'Cheque'"
            :rules="[rules.required, rules.notGreaterThanBudgets]"
            v-maska:[moneyMaskFormat]
          )
    v-card-actions.d-flex.justify-end
      a.mr-3(v-if="!loading" @click="handleAprove()") Aprobar sin pago
      span.mr-3(v-else) Aprobar sin pago
      v-btn(color="primary" @click="handlePay()" :disabled="paymentMethod === 'Cheque'? disabledNewCheck : disabledActions" :loading="loading") Pagar
</template>
<script>
import { mapActions } from 'vuex'
import SideModal from '../shared/SideModal.vue'
import alert from '../shared/alert.vue'
import { vMaska } from 'maska'
import { moneyMaskFormat } from '../../utils/mask'
export default {
  directives: { maska: vMaska },
  props: {
    budgets: Array,
    otID: Number
  },
  components: {
    SideModal,
    alert
  },
  data () {
    return {
      payment_id: null,
      payOtherAmount: '',
      paymentMethod: null,
      showInputPay: false,
      comment: '',
      maxLength: [v => v.length <= 50 || 'Máximo 50 caracteres'],
      budgetsSelected: [],
      isOpenApproveBudget: false,
      loading: false,
      table: {
        headers: [
          { text: 'Presupuestos', value: 'budget_select' },
          { text: 'Total', value: 'total_budget' },
          { text: 'Abono %', value: 'percentage_payment' },
          { text: 'Total Abono', value: 'total_payment' },
        ]
      },
      tableBudget: {
        headers: [
          { text: 'Servicios a realizar', value: 'name', width: '150px'},
          { text: 'Cantidad', value: 'quantity', width: '100px', align: 'center'},
          { text: 'Precio', value: 'price', width: '100px'},
          { text: '', value: 'data-table-expand' },
        ]
      },
      tableSaleItems: {
        headers: [
          { text: 'Repuesto', value: 'model', width: '150px', class: 'itemStyle' },
          { text: 'Cantidad', value: 'quantity', width: '100px', align: 'center', class: 'itemStyle' },
          { text: 'Precio Unitario', value: 'price', width: '100px', class: 'itemStyle' }
        ]
      },
      tableCheck: {
        headers: [
          { text: 'Número de cheque *', value: 'check_number', class: 'blue lighten-5', sortable: false },
          { text: 'Fecha *', value: 'check_date', class: 'blue lighten-5', sortable: false, width: '140px' },
          { text: 'Monto *', value: 'check_amount', class: 'blue lighten-5', filterable: false, sortable: false },
          { text: 'Acciones', value: 'actions', class: 'blue lighten-5', filterable: false, }
        ],
      },
      rules: {
        required: value => !!value || 'Requerido',
        greaterThanZero: value => +value.split('.').join('') > 0 || 'Debe ser mayor a cero',
        isNumber: value => Number.isInteger(+value) || 'Debe ser un número',
        notGreaterThanBudgets: v => {
          return +v.toString().split('.').join('') <= this.getTotalBudgetsAmount() || 'No puede ser mayor que el total a Pagar'
        }
      },
      allChecks: [
        {
          check_number: '',
          check_date: '',
          check_amount: 0,
          disabled: false,
          allowDelete: false
        }
      ],
      moneyMaskFormat,
      localBudgets: [],
      expanded: [],
      menu: false,
      menu2: false,
      credit_term: '',
    }
  },
  computed: {
    disabledActions () {
      const otherAmountInvalid = v => { return +v.toString().split('.').join('') <= this.getTotalBudgetsAmount() || false }
      return this.budgetsSelected.length === 0 || this.paymentMethod == null || this.showInputPay && +this.payOtherAmount.toString().split('.').join('') > 0 && !otherAmountInvalid(this.payOtherAmount) || this.showInputPay && this.payOtherAmount === '' || this.paymentMethod === 'A Crédito' && !(this.credit_term.length > 8) || this.paymentMethod === 'A Crédito' && !this.$refs.credit_term?.valid || !this.showInputPay && !this.totalBudgetToPay > 0
    },
    disabledNewCheck () {
      const disableEmptyChecks = this.allChecks.map(check => {
        return ['', 0,].map(v => Object.values(check).includes(v)).includes(true)
      })
      return this.paymentMethod === 'Cheque' && !this.$refs.otherAmountField?.valid || disableEmptyChecks.includes(true)
    },
    totalBudgetToPay () {
      let total = 0
      this.budgetsSelected.forEach(budget => {
        total = total + budget.budgetPayment
      })
      return total
    }
  },
  watch: {
    budgets () {
      this.localBudgets = this.budgets.map(budget => {
        const percentage = budget.agreement ? 100 : (budget.deposit * 100)
        const budgetPayment = budget.agreement ? budget.total_price : (+budget.deposit * budget.total_price)
        const item = {
          agreement: budget.agreement,
          id: budget.id,
          name: budget.name,
          percentage: percentage,
          total: budget.total_price,
          budgetPayment: budgetPayment,
          checked: false,
          style: '',
          disablePercentageInput: !!budget.agreement
        }
        this.selectBudget(item)
        return item
      })
    },
    paymentMethod: {
      handler(value) {
        if(value === 'Cheque') {
          this.showInputPay = true
        }
      },
      deep: true
    },
    allChecks: {
      handler(value) {
        const lastCheck = this.allChecks.at(-1)
        if(lastCheck.check_amount[0] === '0') {
          lastCheck.check_amount = Number(lastCheck.check_amount) * 1
        }
        this.payOtherAmount = lastCheck.check_amount
        if(this.allChecks.length > 1) {
          const paymentTotal = this.allChecks.reduce((acc, c) => acc + +(c.check_amount.toString().split('.').join(''), 0))
          this.payOtherAmount = paymentTotal
        }
      },
      deep: true
    },
    showInputPay: {
      handler(value) {
        if(!value) {
          this.payOtherAmount = ''
        }
      },
      deep: true
    },
    payOtherAmount: {
      handler(value) {
        if(this.showInputPay) {
          if(value.toString().split('.').join('') === 0) {
            this.payOtherAmount = ''
          } else if(value[0] === '0') {
            this.payOtherAmount = Number(value) * 1
          }
        }
      },
      deep: true
    },
  },
  methods: {
    ...mapActions('ot', ['aproveOrPay']),
    ...mapActions('inventory', ['createAccountReceivableAgreement']),
    open () {
      this.budgetsSelected = []
      this.isOpenApproveBudget = true
    },
    calculateTotalBudget (total, percentage, item) {
      const localPercentage = (typeof percentage === 'string' && percentage === '') ? 0 : percentage
      if (localPercentage > 100) item.percentage = 100
      if (localPercentage < 0) item.percentage = 0
      item.budgetPayment = (parseInt(localPercentage) * total) / 100
    },
    selectBudget (item) {
      if (!this.budgetsSelected.some((b) => b.id === item.id)) {
        this.budgetsSelected.push(item)
        item.checked = true
        item.style = 'color: #000; font-weight: bold'
      } else {
        const index = this.budgetsSelected.indexOf(item)
        this.budgetsSelected.splice(index, 1)
        item.checked = false
        item.style = ''
      }
    },
    sumServicesPrices (services) {
      let total = 0
      if (services) {
        services.forEach(s => {
          total = total + s.service_data.price * s.service_quantity
        })
      }
      return total
    },
    sumItemsPrices (estimateServices) {
      let total = 0
      if (estimateServices) {
        estimateServices.forEach((es) => {
          total = total + es.sale_items_data.reduce((acc, curr) => acc + curr.total_price, 0)
        })
      }
      return total
    },
    sumBudgetsPrices (budget) {
      return this.sumServicesPrices(budget.estimate_services) + this.sumItemsPrices(budget.estimate_services) - budget.discount
    },
    handleAprove () {
      this.loading = true
      const data = {
        estimate_list: this.budgetsSelected.map((budget) => budget.id),
        deposit_order: {}
      }
      this.aproveOrPay({ data }).then(
        (response) => {
          this.isOpenApproveBudget = false
          this.$emit('showAlert', { type: 'success', message: 'Presupuesto APROBADO (sin pago) con éxito', show: true, color: 'green' })
          this.loading = false
        }
      )
    },
    async handlePay () {
      const estimatesWithAgreement = []
      const estimateList = this.budgetsSelected.reduce((filtered, budget) => {
        if (!budget.agreement) {
          filtered.push(budget.id)
        } else {
          estimatesWithAgreement.push(budget.id)
        }
        return filtered
      }, [])
      const data = {
        estimate_list: estimateList,
        deposit_order: {
          payment_total: this.payOtherAmount !== '' ? +this.payOtherAmount.toString().split('.').join('') : this.totalBudgetToPay,
          payment_gateway: this.paymentMethod,
          payment_id: this.payment_id,
          description: this.comment,
          estimates_to_pay: estimateList,
          state: ''
        }
      }

      this.loading = true
      if (estimateList.length > 0) {
        if (this.paymentMethod === 'A Crédito') {
          data.deposit_order.credit_term = this.credit_term
        } else if (this.paymentMethod === 'Cheque') {

          for (const check of this.allChecks) {
            data.deposit_order.check_number = check.check_number
            data.deposit_order.check_date = check.check_date
            data.deposit_order.check_amount = +check.check_amount.toString().split('.').join('')
            data.deposit_order.check_qty = `${Number(this.allChecks.indexOf(check)) + 1}/${this.allChecks.length}`
            data.deposit_order.payment_total = +check.check_amount.toString().split('.').join('')
            data.deposit_order.state = 'Pendiente'

            await this.aproveOrPay({ data }).then(
              (response) => {
                // this.loading = false
                if (response.status === 200) {
                  if(Number(this.allChecks.indexOf(check)) + 1 === this.allChecks.length) {
                    this.isOpenApproveBudget = false
                    this.$emit('showAlert', { type: 'success', message: 'Presupuesto PAGADO con éxito', show: true, color: 'green' })
                  }
                } else {
                  this.$emit('showAlert', { type: 'danger', message: 'Ha ocurrido un error al realizar el pago', show: true, color: 'red' })
                }
              }
            )
          }
        }
        if (this.paymentMethod !== 'Cheque') {
          await this.aproveOrPay({ data }).then(
            (response) => {
              if (response.status === 200) {
                this.isOpenApproveBudget = false
                this.$emit('showAlert', { type: 'success', message: 'Presupuesto PAGADO con éxito', show: true, color: 'green' })
              }
            }
          )
        }
      }
      // genera pagos con convenio por presupuesto
      for (const estimate of estimatesWithAgreement) {
        const dataAccountReceivable = {
          estimates_to_pay: [estimate],
          description: 'Presupuesto con convenio'
        }
        await this.createAccountReceivableAgreement({ dataAccountReceivable }).then(
          (response) => {
            if (response.status === 201) {
              this.isOpenApproveBudget = false
              this.$emit('showAlert', { type: 'success', message: 'Presupuesto PAGADO con éxito', show: true, color: 'green' })
            }
          }
        )
      }
      this.loading = false
    },
    getTotalBudgetsAmount() {
      const paymentTotal = this.budgets.reduce((acc, curr) => acc + curr.total_price, 0)
      return paymentTotal
    },
    newCheck () {
      this.allChecks.push({
        check_number: '',
        check_date: '',
        check_amount: '',
        disabled: false,
        allowDelete: true
      })
    },
    deleteCheck (item) {
      this.allChecks = this.allChecks.filter((c) => c !== item)
      this.allChecks.at(-1).disabled = false
    },
    clearCheck (item) {
      this.allChecks = this.allChecks.map((c) => c !== item? c : {
        ...c,
        check_number: '',
        check_date: '',
        check_amount: '',
        disabled: false,
      })
    },
    resetModal () {
      this.payment_id = null
      this.payOtherAmount = ''
      this.paymentMethod = null
      this.showInputPay = false
      this.comment = ''
      this.budgetsSelected = []
      this.isOpenApproveBudget = false
      this.resetPaymentFields()
    },
    resetPaymentFields () {
      this.allChecks = [
        {
          check_number: '',
          check_date: '',
          check_amount: '',
          disabled: false,
          allowDelete: false
        }
      ]
      this.credit_term = ''
      this.payOtherAmount = ''
      this.showInputPay = false
    },
    conditionalHighlight () {
      return this.payOtherAmount ? 'conditionalHighlight' : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.close-btn {
  position: fixed;
  top: 12px;
  right: 12px;
}
.percentage-input {
  width: 60px;
}
.reference-input {
  width: 100px;
}
.text-grey {
  color: #A3A2A1;
}
.detail {
  margin-left: auto;
  width: 50%;
  .text-black {
    color: #181818;
    font-weight: bold;
  }
}
.text-input {
  max-width: 120px !important;
  margin: 0;
  padding: 0;
}
.conditionalHighlight {
  color: #A3A2A1 !important;
  text-decoration-line: line-through !important;
}
.payment-methods::v-deep div[role="radiogroup"] {
  columns: 3;
  display: block;
  row-gap: 50px;

  &>div {
    padding-top: 10px;
    margin-bottom: 10px;
  }
}
.subtotal-budget, .subtotal-items  {
  height: 100%;
}
.subtotal-color {
  color: #969696;
}
.v-data-table::v-deep th {
  font-size: 14px !important;
  font-weight: 500;
}
.v-data-table::v-deep td {
  font-size: 14px !important;
}
.v-data-table::v-deep .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-style: italic;
  padding: 0px;
  padding-left: 20px;
}
</style>
<style>
.itemStyle {
  color: #969696 !important;
}
</style>
