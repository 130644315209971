<template lang="pug">
v-dialog(v-model="dialog" max-width="1100px")
  v-card.pa-4(v-if="localPieces.length > 0")
    v-card-title.d-flex.flex-column.align-start(v-if="!isSupervisor")
      v-icon(size="48" color="primary") mdi-check-circle
      h3.primary--text.mt-2 Vamos a preparar tu solicitud
    v-card-text.mt-4
      h4.primary--text Resumen de tu solicitud: Proveedor {{ localPieces[0].supplier_name }}
      v-data-table.mt-4(:headers="headers" :items="localPieces" hide-default-footer)
        template(v-slot:item.code="{ item }")
          v-text-field.mt-4(outlined dense v-model="item.code" disabled)
        template(v-slot:item.supplier_code="{ item }")
          v-text-field.mt-4(outlined dense v-model="item.supplier_code" disabled)
        template(v-slot:item.category_code="{ item }")
          v-text-field.mt-4(outlined dense v-model="item.category_code" disabled)
        template(v-slot:item.name="{ item }")
          v-text-field.mt-4(outlined dense v-model="item.name" disabled)
        template(v-slot:item.payed_price="{ item, index }")
          v-text-field.mt-4(outlined dense prefix="$" v-model="item.payed_price" class="end-text" disabled)
        template(v-slot:item.sale_price="{ index, item }")
          v-text-field.mt-4(outlined dense prefix="$" v-model="item.sale_price"  class="end-text" disabled)
        template(v-slot:item.bought_items="{ item }")
          v-text-field.mt-4(outlined dense type="number" v-model="item.bought_items" class="end-text" disabled)
        template(v-slot:item.subtotal="{ item }")
          v-text-field.mt-4(outlined dense prefix="$" :value="item.payed_price * item.bought_items" class="end-text" disabled)
    .d-flex.flex-column.align-end
      .d-flex
        h4.mb-4 Total de la Solicitud:
        span.ml-2 {{ totalPriceSolicitude | currency }}
      .d-flex.mt-4
        v-btn(color="primary" outlined @click="closeDialog") Cancelar
        v-btn.ml-6(color="primary" @click="sendSolicitude") Confirmar
</template>
<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      headers: [
        { text: 'Código repuesto', value: 'code', sortable: false, },
        { text: 'Código proveedor', value: 'supplier_code', sortable: false },
        { text: 'Código categoría', value: 'category_code', sortable: false },
        { text: 'Nombre pieza', value: 'name', sortable: false },
        { text: 'Precio compra', value: 'payed_price', filterable: false, sortable: false },
        { text: 'Precio Venta', value: 'sale_price', filterable: false, sortable: false },
        { text: 'Stock', value: 'bought_items', filterable: false, sortable: false },
        { text: 'Subtotal', value: 'subtotal', filterable: false, sortable: false }
      ],
      localPieces: [],
      invoiceNumber: '',
      dialog: false,
      isEditing: false
    }
  },
  computed: {
    totalPriceSolicitude() {
      return this.localPieces.reduce((acc, piece) => acc + this.getNumericSubtotal(piece.payed_price) * piece.bought_items, 0)
    },
    isSupervisor() {
      const user = JSON.parse(localStorage.getItem('user'))
      return user.groups.includes('Supervisor')
    }
  },
  methods: {
    ...mapActions('inventory', ['createMerchandiseEntryRequest', 'updateMerchandiseEntryRequest', 'setSolicitudeStore']),
    closeDialog() {
      this.dialog = false
    },
    async sendSolicitude() {
      const data = {
        supplier_id: this.localPieces[0].supplier_id,
        invoice_number: this.invoiceNumber,
        sm_list: this.localPieces.map((piece) => ({
          piece_id: piece.id,
          payed_price: +piece.payed_price.split('.').join(''),
          sale_price: +piece.sale_price.split('.').join(''),
          amount: +piece.bought_items,
          subtotal: +piece.subtotal
        }))
      }
      if (!this.isEditing) {
        const response = await this.createMerchandiseEntryRequest(data)
        if (response.status === 201) {
          this.$toast.success('Solicitud de mercadería creada exitosamente')
          this.$router.push({ name: 'Mis Solicitudes de Mercadería' })
        } else {
          this.$toast.error('Error al crear la solicitud de mercadería')
        }
      } else {
        if (this.supervisorIsEditing(data)) return
        const id = this.$route.params.stockID
        const response = await this.updateMerchandiseEntryRequest({ id, data })
        if (response.status === 200) {
          this.$toast.success('Solicitud de mercadería actualizada exitosamente')
          this.$router.push({ name: 'Mis Solicitudes de Mercadería' })
        } else {
          this.$toast.error('Error al actualizar la solicitud de mercadería')
        }
      }
    },
    open(data, isEditing = false) {
      this.isEditing = isEditing
      this.localPieces = data.localPieces;
      this.invoiceNumber = data.invoiceNumber
      this.dialog = true
    },
    getNumericSubtotal(subtotal) {
      if (!subtotal) return 0
      if (typeof subtotal === 'number') return subtotal
      return +subtotal.split('.').join('')
    },
    supervisorIsEditing(data) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.groups.includes('Supervisor')) {
        this.setSolicitudeStore(data)
        this.$emit('updatedBySupervisor')
        this.dialog = false
        return true
      }
      return false
    }
  }
}
</script>