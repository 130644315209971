<template lang="pug">
v-col(class="fill-height")
  v-card-title(
    class="headline font-weight-medium pt-1 pl-0 mb-2"
    style="color: #4C7AEC; font-size: 1.8rem !important;"
  ) Agenda tus servicios aquí

  v-row
    v-col
      v-toolbar(
        flat
      )
        v-btn(
          outlined
          class="mr-4"
          color="grey darken-2"
          @click="setToday"
        ) Hoy

        v-btn(
          fab
          text
          small
          color="grey darken-2"
          @click="prev"
        )
          v-icon(small) mdi-chevron-left
           
        v-toolbar-title(v-if="$refs.calendar") {{ $refs.calendar.title.charAt(0).toUpperCase() + $refs.calendar.title.slice(1) }}
  
        v-btn(
          fab
          text
          small
          color="grey darken-2"
          class="mr-4"
          @click="next"
        )
          v-icon(small) mdi-chevron-right
          
        v-menu(
          bottom
          right
        )
          <template v-slot:activator="{ on, attrs }">
            v-btn(
              outlined
              color="grey darken-2"
              v-bind="attrs"
              v-on="on"
            )
              span {{ typeToLabel[type] }}
              v-icon(right) mdi-menu-down
    
          </template>

          v-list
            v-list-item(@click="setRange('day')")
              v-list-item-title Día
            
            v-list-item(@click="setRange('week')")
              v-list-item-title Semana
            
            v-list-item(@click="setRange('month')")
              v-list-item-title Mes
         
        v-spacer

        v-btn(
          outlined
          color="white"
          style="background-color: #4C7AEC; height: 45px; font-size: 0.8rem;"
          @click="openModalAppointment(true)"
              
        )
          span NUEVA CITA
              
         
      v-sheet(height="600")
        v-calendar(
          
          locale="cl-Es"
          ref="calendar"
          v-model="focus"
          class="calendar"
          color="primary"
          event-more-text="{0} más"
          :first-interval= 8
          :interval-minutes= 60
          :interval-count= 10
          :events="[...appointments, ...disabledSchedule]"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        )
          <template v-slot:event="{ event }">
            div.pl-1(:ref="event.id") 
              span.v-event-sumary
                v-col.ma-0.pa-0(
                  style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                ) 
                  strong {{ event.name }}
                v-col.ma-0.pa-0(
                  style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                ) {{ getStartEndEvent(event) }}
          </template>

      v-menu(
        min-width="450px"
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      )
        v-card(
          color="grey lighten-4"
          min-width="350px"  
          flat
        )
          v-toolbar(
            :color="serviceColor[selectedEvent?.service?.name]"
            dark
          )
            v-toolbar-title(v-html="selectedEvent?.name")
            v-spacer
            v-icon(
              @click="dialog = true"
            ) mdi-delete
          
          v-card-text
            v-card-text             
              h3 Estatus:                                                    
              v-btn(
                tile dark 
                :color="appointmentState[selectedEvent.state]?.color"
                height="20px"    
              ) {{ appointmentState[selectedEvent.state]?.text }}
                                                   
              h3 Servicio: {{ selectedEvent.service?.name }}            
              h3 Duración: {{ selectedEvent.service?.duration + ' Hora(s)' }}              
              h3 Cliente: {{ selectedEvent.customer?.full_name }} <br/>                 
              h3 Vehículo: {{ selectedEvent.vehicle?.model_display }} <br/>                     
              h3 Patente: {{ selectedEvent.vehicle?.patent }} <br/>
              h3 Rut: {{ selectedEvent.customer?.rut }} <br/>                         
              h3 Contacto: (+56) {{ selectedEvent.customer?.contact || cellFilter }} <br/>        
              h3 Dirección: {{ selectedEvent.customer?.address }}

              v-btn(v-if="selectedEvent.state === 'SCHEDULED'"
                color="primary"
                elevation="2"
                dark
                tile
                class="my-1"
                @click="$refs.ModalOTForm.open()"
              ) Convertir a OT
              
              h3(v-if="selectedEvent.work_order"
                color="teal"
                tile
              ) OT: N° {{ selectedEvent.work_order }} 
                router-link.ml-2(:to="{ name: 'Detalle OT', params: {otID: selectedEvent.work_order }}") Ver detalles
                                           
              div(v-if="selectedEvent.state === 'REQUESTED'"                
              )
                v-btn(
                  color="blue-grey"
                  elevation="2"
                  dark                 
                  class="mr-3 my-1"                 
                  @click="() => rescheduleAppointment(selectedEvent)"
                ) Reagendar
                                  
                v-btn(
                  color="primary"
                  elevation="2"
                  dark                  
                  class="mx-2 my-1"
                  :loading="isApproving"
                  @click="() => approveAppointment(selectedEvent.id)"
                ) Aprobar
                                    
          v-dialog(
            v-model="dialog"
            max-width="350"
            justify="center"
          )
            v-card(
              class="pb-4 text-center d-inline-block"
              color="grey lighten-4"
              dark              
            )
              v-toolbar(
                :color="serviceColor[selectedEvent?.service?.name]"
                dark
              ) ¿Deseas eliminar esta cita?
            
              v-card-actions(style="justify-content: center")
                v-btn(
                  class="mt-4"
                  color="blue-grey"
                  plain
                  @click="dialog = false"
                ) CANCELAR
              
                v-btn(
                  :loading="isDeleting"
                  class="mt-4"
                  color="error"
                  plain
                  @click="handleDeleteAppointment(selectedEvent.id)"
                ) ELIMINAR
          
    v-card(
      class="mt-3"
      style=" display:flex; flex-direction: column; background: none; height: 100%; box-shadow: none; gap: 1rem"
    ) 
      ToApproveList(@showAppointment="showAppointment")

      v-date-picker(
        locale="es-es"
        v-model="focus2"
        color="#2784FF"
        width="260"
        no-title
        @click:date="viewDay2"
        @change="updateRange"
        style="border: solid 1.3px #DDD; border-radius: 7px; height: fit-content;"
      )
                    
  ModalAppointment(ref="ModalAppointment"  :getTodayAppointments="() => listTodayAppointments(picker2)")
  OTForm(ref="ModalOTForm" :formScheduleOT="selectedEvent" @save="handleOTSaveSuccess")

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModalAppointment from '../components/schedule/ModalAppointment.vue';
import OTForm from '../components/sale/OTForm.vue';
import axios from '../plugins/axios';
import { cellFilter } from '@/utils/filters.js'
import { palette } from '../utils/appointmentColors';
import ToApproveList from '../components/schedule/ToApproveList.vue';

export default {
  components: {
    ModalAppointment,
    OTForm,
    ToApproveList,
  },
  filters: { cellFilter },
  data: () => ({
    focus: '',
    focus2: null,
    type: 'week',
    typeToLabel: {
      month: 'Mes',
      week: 'Semana',
      day: 'Día',
      // '4day': '4 Días',
    },

    otId: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,

    dialog: false,
    isDeleting: false,
    isConvertibleOt: false,
    isApproving: false,    
    
    picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 10),

    picker2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 10),

    appointmentState: {
      REQUESTED: {
        text: 'Solicitado',
        color: '#aebdf0', 
      },
      SCHEDULED: {
        text: 'Agendado',
        color: '#7b90da',
      },
      RESCHEDULED: {
        text: 'Reagendado',
        color: '#aebdf0',
      },
      DONE: {
        text: 'Realizado',
        color: '#1bc47d'
      },
      CANCELED: {
        text: 'Cancelado',
        color: 'skyblue'
      }
    },
    palette: palette,
    serviceColor: {},
    serviceCounter: 1,
    
  }),

  computed: {
    ...mapGetters(
      "schedule", 
      [
        'focusDay', 
        'services', 
        'appointments', 
        'todayAppointments', 
        'disabledSchedule',
        'currentAppointment',
      ]
    ),
  },

  async mounted () {
    this.$refs.calendar.checkChange()
  },

  created () {
    this.listAppointments()
    this.listServices()
    // this.listTodayAppointments(this.picker2)
    this.listDisabledSchedule();
  },

  methods: {
    ...mapActions(
      "schedule", 
      [
        'updateFocus', 
        'listServices', 
        'listAppointments', 
        'getAppointment', 
        'listTodayAppointments', 
        'listDisabledSchedule',
        'deleteAppointment',
        'updateCurrentAppointment'  
      ]
    ),

    async approveAppointment() {
      const id = this.selectedEvent.id;
      this.isApproving = true;    
      const response = await axios.post(`/schedule/approve_appointment/${id}/`);
      if(response) {
        console.log('evento aprobado', id)
        this.listAppointments();
        this.selectedOpen = false;
        this.isApproving = false;
      }
      else {
        this.isApproving = false;
        console.log('No se pudo aprobar la cita')
      }
    },

    async rescheduleAppointment (appointment) {
      await this.updateCurrentAppointment(appointment)
      this.openModalAppointment();
    },

    getToday () {
      const today = new Date();
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return today.toLocaleDateString('cl-Es', options)
    },
    viewDay ({date}) {
      this.focus = date
      this.type = 'day'
      this.picker=date
      this.updateFocus(date) 
    },

    viewDay2 (date) {
      this.focus = date
      this.type = 'day'
      this.updateFocus(date) 
    },

    getEventColor (event) {
      if(!event.state) {
        return event.color
      }
      else{
        if(this.serviceColor?.[event.service.name]) {
          
          return this.serviceColor[event.service.name]
        } else {
          this.serviceColor[event.service.name] = palette[this.serviceCounter]
          this.serviceCounter < 7? this.serviceCounter++ : this.serviceCounter = 1; 
          return palette[this.serviceCounter]
        }
      }
    },
    setToday () {
      this.focus = ''
      this.picker = this.picker2
      this.type = 'day'
      this.updateFocus(this.picker)
    },

    setRange(type) {
      this.type = type;  
    },

    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      // console.log(event)
      const open = async () => {
        this.selectedEvent = event 
        if(event.color === "#DDD") return
        this.selectedElement = nativeEvent.target

        if(this.selectedEvent.id) {
          // eslint-disable-next-line no-return-assign
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))    
        }
      }

      if (this.selectedOpen) {          
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
          open()
      }

      nativeEvent.stopPropagation()
    },
    showAppointment ({ $event, appointment }) {
      const open = async () => {
        this.selectedEvent = appointment 
        
        await this.updateFocus(appointment.start.split(' ')[0])
        this.selectedElement = this.$refs[appointment.id]
        
        if(this.selectedEvent.id) {
          // eslint-disable-next-line no-return-assign
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))    
        }
      }

      if (this.selectedOpen) {          
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
          open()
      }      
    },

    getStartEndEvent (event) {
      const start = new Date(event.start).toLocaleTimeString('es-VE', { hour: 'numeric', minute: 'numeric', hour12: true });
      const end = new Date(event.end).toLocaleTimeString('es-VE', { hour: 'numeric', minute: 'numeric', hour12: true });
      return `${start} - ${end}`;
    },

    updateRange() {
      
    },

    async handleDeleteAppointment(id) {
      this.isDeleting = true;
      const response = await this.deleteAppointment(id)
      if(response) {
        await this.listAppointments()
        setTimeout(() => {
          this.isDeleting = false;
          this.selectedEvent = {}
          this.dialog = false;
          this.selectedOpen = false
        }, 200)
      }
      else {
        console.log(response)
      }
    },

    async handleOTSaveSuccess (OT) {
      await this.listAppointments();
    },

    openModalAppointment () {
      this.$refs.ModalAppointment.open()
      this.listTodayAppointments(this.picker2)
    },
  },
  
  watch: {
    focusDay: {
      handler(val) {
        this.focus2 = this.focusDay;
        this.focus = this.focusDay;
      },
      deep: true,
    }
  }
}


</script>

<style lang="scss">

.v-calendar .v-event-timed-container {
  margin-right: 0;
}

.v-event {
  width: 97% !important
}

.v-date-picker-table table tbody {
  & tr :first-of-type  {
    & button :first-of-type {
      color: red;     
    }
  };
}

.v-date-picker-table table thead {
  & tr :first-of-type  {   
      color: red;    
  }
}

</style>
