<template lang="pug">
v-form(:disabled="readOnly" ref="form" @submit.prevent="onSubmit")
  alert(style="text-align: end !important; position: absolute !important;" ref="alert" v-show="!noFeedback")
  v-row(dense)
    v-col(cols=6)
      v-text-field(v-model="customer.name" label="Nombre" outlined :rules="[v => !!v || 'El nombre es requerido']")
    v-col(cols=6)
      v-text-field(v-model="customer.last_name" label="Apellido" outlined)
    v-col(cols=6)
      v-text-field(
          v-model="customer.contact"
          label="Celular"
          outlined
          :error-messages="errors.contact"
          type="tel"
          maxLength="11"
          v-maska="maskaContact" data-maska="# #### #### ####"
          prefix="(+56)"
          :rules="[rules.celularRequired, rules.celularLength]"
          @focus="$event.target.selectionStart = 7; $event.target.selectionEnd = -1"
        )
    v-col(cols=6)
      v-text-field(
        v-model="customer.rut" label="Rut" outlined
        v-maska="maskaRut" data-maska="1.11#-V" data-maska-tokens="1:[0-9]:repeated|V:[0-9Kk]" data-maska-reversed
      )
    v-col(cols=6)
      v-text-field(v-model="customer.email" label="Correo" outlined :rules="[v => /.+@.+\..+/.test(v) || 'El correo es requerido']")
    v-col(cols=6)
      v-text-field(v-model="customer.address" label="Dirección" outlined)
  v-btn(v-if="!readOnly" block color="primary" x-large type="submit" :disabled="!validateUser") {{ customer.id ? 'Guardar' : '+ Crear Cliente' }}

  v-card(color="#4ade80" flat v-if="saveSuccess && !noFeedback")
    h5.ma-1.pa-2(style="color: #FFFFFF; text-align: center;") Guardado con éxito!
  v-card(color="#fc8181" flat v-if="saveError && !noFeedback")
    h5.ma-1.pa-2(style="color: #FFFFFF; text-align: center;") No se pudo guardar, intenta de nuevo
</template>

<script>
import { mapActions } from 'vuex'
import { vMaska } from 'maska'
import Alert from '../shared/alert.vue'

export default {
  directives: { maska: vMaska },

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    customerSelected: {
      type: Object,
    },
    // Deshabilita las alertas de error/success
    noFeedback: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Alert
  },
  data() {
    return {
      customer: {
        // id: this.customerData?.id ? this.customerData.id : null,
        // name: this.customerData?.id ? this.customerData.name : null,
        // last_name: this.customerData?.id
        //   ? this.customerData?.last_name
        //   : null,
        // rut: this.customerData?.id ? this.customerData.rut : null,
        // address: this.customerData?.id
        //   ? this.customerData.address
        //   : null,
        // email: this.customerData?.id ? this.customerData.email : null,
        // contact: this.customerData?.contact || '9',
        id: null,
        name: null,
        address: null,
        email: null,
        contact: '9'
      },
      errors: {
        rut: [],
        contact: [],
      },
      maskaRut: { unmasked: null },
      maskaContact: { unmasked: null },
      saveSuccess: false,
      saveError: false,
      rules: {
        celularRequired: v => !!v || 'El celular es requerido',
        celularLength: v => v.replace(/ /g,'').length === 9 || 'El celular debe tener 9 caracteres'
      }
    }
  },
  methods: {
    ...mapActions('customer', ['createCustomer', 'editCustomer']),
    async onSubmit() {
      this.errors = {
        rut: [],
        contact: [],
      }
      this.saveSuccess = false
      this.saveError = false

      if (!this.$refs.form.validate()) return

      this.customer.rut = this.maskaRut.unmasked
      this.customer.contact = this.maskaContact.unmasked

      const actions = this.customer.id ? this.editCustomer : this.createCustomer
      const data = this.customer.id
        ? { customerID: this.customer.id, data: this.customer }
        : { data: this.customer }

      const response = await actions(data)
      if (response.status === 201 || response.status === 200) {
        this.$emit('save', response.data)
        this.$emit('input', response.data)
        this.$emit('change', response.data)

        this.$refs.alert.handleShowAlert('success', 'Guardado con éxito', true, 'green')
        this.saveSuccess = true
        setTimeout(() => {
          this.saveSuccess = false;
        }, 4000);

        this.customer.rut = null
        this.customer.contact = '9'

      } else if (response.data.includes('<!DOCTYPE html>') && response.data.includes('DETAIL:')){
        const errorMessage = response.data.split('DETAIL:')[1].split('.')[0]
        this.$refs.alert.handleShowAlert('error', errorMessage, true, 'red')

      } else {
        this.errors = {
          rut: response.data.rut ? response.data.rut : [],
          contact: response.data.contact ? response.data.contact : [],
        }
        this.$refs.alert.handleShowAlert('error', 'Error al intentar guardar', true, 'red')
        this.$emit('error', response.data)
      }
    },
  },
  computed: {
    validateUser () {
      return this.customer.name !== '' && this.customer.contact !== '' && this.customer.contact.replace(/ /g,'').length === 9
    },
  },
  mounted () {
    if (this.customerSelected?.id) {
      const customer = { ...this.customerSelected }
      this.customer = customer
    }
  },
  watch: {
    customerSelected: {
      handler(newValue) {
        this.customer = newValue
        this.customer.contact = this.customer.contact || '9'
        this.$refs.form.resetValidation()
      },
    },
    form: {
      deep: true,
      handler() {
        this.$emit('input', this.costumer)
        this.$emit('change', this.costumer)
      },
    },
  },
}
</script>
