<template lang="pug">
v-menu(v-model="isOpen" offset-x :close-on-content-click="false")
  template(v-slot:activator="{ on, attrs }")
    .d-flex(key="hola")
      v-badge(v-for="(techId, index) in currentTechsIds" :key="index" bordered bottom color="primary" content="D" offset-x="12" offset-y="12")
        v-avatar.mr-1(color="#FFD699" size=32 dark  v-bind="attrs" v-on="on") {{getAvatarTextById(techId)}}
      v-badge(v-if="showAdd" bordered bottom color="primary" content="D" offset-x="12" offset-y="12")
        v-btn(style="border-style: dotted;" size=32 v-bind="attrs" v-on="on" icon)
          v-icon mdi-account-plus-outline
  v-card
    v-card-text
      div Asignar para diagnóstico
      v-form(ref="form" @submit.prevent="onSubmit")
        v-autocomplete.mb-2(
          v-for="(estimate, index) in estimates"
          :key="estimate.id"
          v-model="myTechnicians[index].id"
          :label="`Presupuesto N° ${estimate.id} (${estimate.state})`"
          placeholder="Buscar"
          :items="technicians"
          item-text="first_name"
          item-value="id",
          :disabled="!available")
        v-btn(v-if="available" block color="primary" type="submit" :loading="loading") Guardar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Number
    },
    techId: {
      type: [Number, Array],
      default: null
    },
    OTId: {
      type: Number,
      required: true
    },
    available: {
      type: Boolean,
      default: true
    },
    state: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      key: 'loading',
      isOpen: false,
      loading: false,
      myTdTech: this.techId,
      myTechnicians: [],
      estimates: []
    }
  },
  methods: {
    ...mapActions('estimate', ['listEstimates', 'setEstimateEvaluationTech']),
    async onSubmit () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true
      for (const data of this.myTechnicians) {
        await this.setEstimateEvaluationTech({ estimateId: data.estimateId, data })
      }
      this.$emit('save')
      this.isOpen = false
      this.$emit('showAlert', { type: 'success', message: 'El Técnico ha sido asignado con éxito', show: true, color: 'green' })
      this.fetchData()
      this.loading = false
    },
    getAvatarTextById (id) {
      const tech = this.getTechById(id)
      if (tech) {
        return tech.first_name.charAt(0) + tech.last_name.charAt(0)
      }
      return ''
    },
    getTechById (id) {
      if (id) {
        return this.technicians.find(el => el.id === id)
      }
      return null
    },
    async fetchData () {
      const response = await this.listEstimates({ params: { work_order: this.OTId } })
      if (response.status === 200) {
        this.estimates = response.data.results.filter(el => el.requires_evaluation && el.evaluation)
        this.estimates.reverse()
        this.myTechnicians = this.estimates.map(el => ({ id: el.evaluation.assigned_to, estimateId: el.id }))
      }
    }
  },
  computed: {
    ...mapGetters('user', ['technicians']),
    tech () {
      if (this.techId) {
        return this.technicians.find(el => el.id === this.techId)
      }
      return null
    },
    showAdd () {
      return this.currentTechsIds.length < this.myTechnicians.length
    },
    currentTechsIds () {
      return this.estimates.filter(el => el.evaluation.assigned_to).map(el => el.evaluation.assigned_to)
    }
  },
  watch: {
    state () {
      this.fetchData()
    },
    OTId() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  },
}
</script>
