<template lang="pug">
v-dialog(v-model="dialog" transition="dialog-top-transition" width="350" content-class="elevation-0" style="border-radius: 16px;" persistent)
  v-card
    v-card-title.pa-4.primary--text Nuevo Servicio
    v-card-text.pa-4
      v-row
        v-col(cols="12")
          v-text-field(
            v-model="service.name" 
            label="Nombre*" 
            outlined 
            :rules="[rules.name]" 
            required 
            placeholder="Ej: Cambio de aceite" 
            hide-details 
            dense)
        v-col(cols="12")
          v-text-field(
            v-model="service.price" 
            label="Precio*" 
            outlined
            prefix="$"
            :rules="rules.price" 
            required 
            hide-details 
            dense
            v-maska:[moneyMaskFormat])
      v-row
        v-col(cols="10")
          v-text-field(v-model="service.commission" label="Comisión" outlined type="number" placeholder="Ej: 0.2" hide-details dense)
        v-col.px-0(cols="2")
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-icon.mt-2(v-bind="attrs" v-on="on") mdi-information
            span Valor de la comisión que se asigna al técnico. Ej: 0.2
        v-col(cols="12")
          v-select(v-model="service.category" label="Categoría*" outlined :items="categories" item-text="name" item-value="id" :rules="[rules.category]" required hide-details dense)
      small * Campos obligatorios
    v-card-actions.pa-4.d-flex.justify-end
      v-btn(color="primary" text @click="close") Cancelar
      v-btn(color="primary" @click="handleCreateService") Guardar
</template>
<script>
import { mapActions } from 'vuex'
import { vMaska } from 'maska'
import { moneyMaskFormat } from '../../../utils/mask'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  directives: { maska: vMaska },
  data () {
    return {
      dialog: false,
      service: {
        name: '',
        price: null,
        commission: null,
        category: null,
        service_code: null,
      },
      categories: [],
      rules: {
        name: (v) => !!v || 'El nombre es requerido',
        price: [
          v => !!v || 'El precio es requerido',
          v => (v && +v.toString().split('.').join('') > 0) || 'Debe ser mayor a cero',
          v => (v && +v.toString().split('.').join('')) || 'Debe ser un número'
        ],
        category: v => !!v || 'La categoría es requerida',
      },
      moneyMaskFormat,
    }
  },
  methods: {
    ...mapActions('inventory', ['listCategoryService', 'createNewService']),
    async open(name) {
      this.resetService()
      this.dialog = true
      this.service.name = name
      const res = await this.listCategoryService()
      this.categories = res.data
    },
    resetService () {
      this.service = {
        name: '',
        price: null,
        commission: null,
        category: null,
        service_code: null,
      }
    },
    close () {
      this.dialog = false
      this.$emit('input', false)
    },
    async handleCreateService () {
      if (this.service.name && this.service.price && this.service.category) {
        this.service.price = +this.service.price.split('.').join('')
        const res = await this.createNewService(this.service)
        if (res.status === 200) {
          this.$emit('serviceCreated', res.data)
        } else {
          this.$toast.error('Error al crear el servicio')
        }
        this.dialog = false
      }
    }
  }
}
</script>
<style scoped>

</style>