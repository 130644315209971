<template lang="pug">
v-container(v-if="ot").flex-col.gap-2
  v-row
    v-btn.float-left.mr-3.ml-1(elevation="0" color="primary" @click="$router.back()" icon)
      v-icon mdi-arrow-left
  v-row
      v-col(cols="6")
        v-card.rounded-xl.dataCard
          v-row
            v-col(cols="4").col-md-3.col-lg-4
              div.client-img.graphics
            v-col(cols="8").col-md-9.col-lg-8
              div
                v-card-title.pl-0 Datos del Cliente
                v-card-text.py-0.pl-0 {{ ot.customer_data.full_name }}
                v-card-text.py-0.pl-0 {{ ot.customer_data.address }}
                v-card-text.py-0.pl-0 (+56) {{ ot.customer_data.contact | cellFilter }}
                v-card-text.py-0.pl-0 {{ ot.customer_data.email }}
      v-col(cols="6")
        v-card.rounded-xl.dataCard
          v-row
            v-col(cols="4").col-md-3.col-lg-4
              div.vehicle-img.graphics
            v-col(cols="8").col-md-9.col-lg-8
              div
                v-card-title.pl-0 Datos del Vehículo
                v-card-text.py-0.pl-0 {{ ot.vehicle_data.patent }}
                v-card-text.py-0.pl-0 {{ ot.vehicle_data.model_display }}
                v-card-text.py-0.pl-0 N° motor: {{ ot.vehicle_data.engine_number }}
                v-card-text.py-0.pl-0 N° chasis: {{ ot.vehicle_data.chassis_number }}
                v-card-text.py-0.pl-0(v-if="milage != null") {{ milage }} Kms.
                v-card-text.py-0.pl-0(v-else) Kilometraje no registrado.

  v-card.mb-2
    .d-flex.align-center.pa-2
      h3.mr-5 Trabajos completados y aprobados (OT N° {{ ot.id }})
      v-chip(
        label
        :text-color="getStatusTextColor(ot.state)"
        :color="getStatusColor(ot.state)"
        ) {{ ot.state }}

  v-row
    v-col(cols="5").flex-col.gap-2
      .flex-col.gap-2.w-100(v-for="estimate in estimateList" :key="estimate.id")
        h3.pa-0 Presupuesto N° {{ estimate.id }}
        .estimate-card.flex-col.gap-1.w-100.pa-3(:class="{ 'added-estimate': estimate.isAdded }")
          h4.pa-0.primary--text Servicio
          v-row(dense)
            v-col
              v-text-field(hide-details :value="estimate.estimate_services[0].service_data.name" outlined disabled)
            //- v-col(cols="3")
            //-   v-text-field(hide-details :value="estimate.estimate_services[0].unit_price" outlined type="number" prefix="$" label="Total" disabled)
            v-col(cols="2")
              v-text-field(hide-details :value="estimate.estimate_services[0].service_quantity" outlined type="number" label="Cantidad" min=1)
            v-col(cols="3")
              v-text-field(hide-details :value="estimate.estimate_services[0].total_price" outlined type="number" prefix="$" label="Total" disabled)
          h4.pa-0.primary--text Repuestos
          v-row(dense v-for="item in estimate.estimate_services[0].sale_items" :key="item.id")
            v-col
              v-text-field(v-if="item.new_piece_name == null" hide-details :value="item.piece_name" outlined disabled)
              v-text-field(v-else :value="item.new_piece_name" hide-details outlined disabled)
                template(#append)
                  div.mr-2.ml-2
                    v-chip.compatible(color="primary" outlined small pill) nuevo
            //- v-col(cols="3")
            //-   v-text-field(hide-details :value="item.unit_price" outlined type="number" prefix="$" label="Total" disabled)
            v-col(cols="2")
              v-text-field(hide-details :value="item.quantity" outlined type="number" label="Cantidad" min=1)
            v-col(cols="3")
              v-text-field(hide-details :value="item.total_price" outlined type="number" prefix="$" label="Total" disabled)
        .flex-row.justify-end(v-if="!estimate.isAdded")
          v-btn(color="primary" outlined @click="addService(estimate)")
            span Añadir
            v-icon mdi-chevron-right

    v-col(cols="7")
      h3.pa-0 Detalles OT
      OTServiceInput(
        v-model="otServices"
        :vehicle="ot.vehicle_data"
        :isQuotation="false"
        @removeService="handleServiceRemoved"
        )
      v-row(v-if="saleItemsToCreate.length > 0" dense)
        v-col(cols="12")
          .new-pieces-alert
            .d-flex.justify-space-between
              .d-flex
                v-icon.mr-2 mdi-information-outline
                span(v-if="saleItemsToCreate.length > 1") Los siguientes repuestos deben ser ingresados al inventario:
                span(v-else) El siguiente repuesto debe ser ingresado al inventario:
            ul.new-pieces-list
              li(v-for="item in saleItemsToCreate" :key="item.new_piece_name") {{ item.new_piece_name }}

      .mt-4.flex-col.align-end(v-if="otTotalPrice")
        h3.pa-0 Total OT
        h3.pa-0.text--black {{ otTotalPrice | currency }}

      .mt-4.flex-row
        v-btn(v-if="saleItemsToCreate.length > 0" color="primary" @click="showCreateNewPiecesModal = true" block) Ingresar repuestos
        v-btn(v-else color="primary" @click="onSubmit" block) Guardar

  CreateNewPiecesModal(
    v-model="showCreateNewPiecesModal"
    :itemsToCreate="saleItemsToCreate"
    @close="showCreateNewPiecesModal = false"
    @created="handlePiecesCreated"
  )
</template>

<script>
import { mapActions } from 'vuex'
import { cellFilter } from '@/utils/filters.js'
import otStatusColors from '@/shared/otStatusColors'
import OTServiceInput from '@/components/sale/subcomponents/OTServiceInput.vue'
import CreateNewPiecesModal from '@/components/sale/subcomponents/CreateNewPiecesModal.vue'

export default {
  components: {
    OTServiceInput,
    CreateNewPiecesModal,
  },

  data() {
    return {
      loading: true,
      ot: null,
      otServices: [],
      milage: null,
      recomputeEstimateListKey: 0,

      showCreateNewPiecesModal: false,
    }
  },

  filters: { cellFilter },

  computed: {
    estimateList() {
      this.recomputeEstimateListKey // eslint-disable-line
      const notAdded = this.ot.estimate.filter((e) => !e.isAdded)
      const added = this.ot.estimate.filter((e) => e.isAdded)
      return [...notAdded, ...added]
    },

    saleItemsToCreate() {
      const saleItems = this.otServices.flatMap(
        (service, serviceIdx) =>
          service?.saleItems?.map((item, idx) => {
            return {
              ...item,
              pieceIndex: idx,
              serviceIndex: serviceIdx,
            }
          }) ?? []
      )
      return saleItems.filter((item) => item.new_piece_name != null)
    },

    otTotalPrice() {
      return this.otServices.reduce((acc, service) => {
        const servicePrice =
          service.service.price * service.service.service_quantity ?? 0
        const saleItemsPrice =
          service.saleItems?.reduce((acc, item) => acc + item.total_price, 0) ??
          0
        return acc + servicePrice + saleItemsPrice
      }, 0)
    },
  },

  methods: {
    ...mapActions('ot', ['getOT', 'updateOT']),
    ...mapActions('inventory', ['getVehicleMilage', 'listPieces']),
    ...mapActions('service', ['listServicesInWorkOrder', 'listServices']),

    getStatusTextColor(state) {
      return otStatusColors[state]
        ? otStatusColors[state].textColor
        : otStatusColors.default.textColor
    },

    getStatusColor(state) {
      return otStatusColors[state]
        ? otStatusColors[state].color
        : otStatusColors.default.color
    },

    async fetchData() {
      this.loading = true
      await this.getOT({ OTId: this.$route.params.otID }).then((response) => {
        if (response.status < 200 || response.status >= 300) {
          this.$toast.error('Error al cargar la OT')
          return
        }
        this.ot = response.data
        const temp = response.data.services.map((estimateService) => {
          console.log('service', estimateService)

          return {
            ...estimateService,
            source_estimate_service: estimateService.source_estimate_service,
            service_quantity: estimateService.quantity,
            price: estimateService.unit_price,
            saleItems: estimateService.sale_items.map((item, idx) => {
              return {
                ...item,
                piece: estimateService.sale_items_data[idx].piece,
                price: item.unit_price,
                search: item.piece_name,
                new_piece_name: null,
              }
            }),
          }
        })
        this.otServices.push(...temp)
        this.removeAlreadyAddedServiecs()
      })

      if (!this.ot) return

      await this.getVehicleMilage({ id: this.$route.params.otID }).then(
        (response) => {
          if (response.status < 200 || response.status >= 300) {
            this.$toast.error('Error al cargar el kilometraje')
            return
          }
          this.milage = response.data.milage
        }
      )
      this.loading = false
    },

    /** Quita de la lista de presupuestos aquellos cuyos trabajos ya se agregaron */
    removeAlreadyAddedServiecs() {
      this.otServices.forEach((service) => {
        const estimate = this.ot.estimate.find(
          (e) => e.estimate_services[0].id === service.source_estimate_service
        )
        if (!estimate) return
        estimate.isAdded = true
        this.recomputeEstimateListKey++
      })
    },

    /** Mueve un trabajo desde un presupuesto a la OT */
    addService(estimate) {
      const estimateService = estimate.estimate_services[0]
      const mappedService = {
        source_estimate_service: estimateService.id,
        service: {
          ...estimateService.service_data,
          id: estimateService.service,
          service_quantity: estimateService.service_quantity,
          price: estimateService.unit_price,
        },
        saleItems: estimateService.sale_items.map((item, idx) => {
          return {
            ...item,
            piece: estimateService.sale_items_data[idx].piece,
            price: item.unit_price,
          }
        }),
      }
      this.otServices.push(mappedService)
      estimate.isAdded = true
      this.recomputeEstimateListKey++
    },

    handleServiceRemoved(service) {
      const estimate = this.ot.estimate.find(
        (e) => e.estimate_services[0].id === service.source_estimate_service
      )
      if (!estimate) return
      estimate.isAdded = undefined
      this.recomputeEstimateListKey++
    },

    handlePiecesCreated(pieces) {
      pieces.forEach((piece) => {
        const saleItem =
          this.otServices[piece.serviceIndex].saleItems[piece.pieceIndex]
        saleItem.piece = piece
        saleItem.new_piece_name = null
      })
    },

    async onSubmit() {
      this.loading = true
      const services = this.otServices.map((service) => {
        return {
          service: service.service.id,
          service_quantity: service.service.service_quantity,
          unit_price: service.service.price,
          source_estimate_service: service.source_estimate_service,
          sale_items: service.saleItems.map((item) => {
            return {
              piece: item.piece.id,
              quantity: item.quantity,
              unit_price: item.price,
            }
          }),
        }
      })
      const OTId = this.$route.params.otID
      const data = {
        services,
      }
      await this.updateOT({ OTId, data })
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            this.$toast.error('Error al guardar la OT')
            return
          }
          this.$toast.success('OT guardada correctamente')
          this.$router.back()
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.fetchData()
    })
  },
}
</script>

<style lang="scss" scoped>
.dataCard {
  color: #3658d3;
  height: 200px;
  @media (min-width: 1025px) and (max-width: 1600px) {
    height: 200px;
  }
  @media (max-width: 1024px) {
    height: 200px;
  }
}

h2 {
  color: #3658d3;
}

.v-card__title,
.v-card__text {
  @media (min-width: 1025px) and (max-width: 1600px) {
    font-size: 0.9rem;
  }
  @media (max-width: 1024px) {
    font-size: 0.8rem;
    margin-left: 7px;
  }
}

.graphics {
  width: 150px;
  height: 150px;
  margin-left: 50px;

  @media (min-width: 1025px) and (max-width: 1600px) {
    width: 100px !important;
    height: 100px !important;
    margin-left: 40px !important;
    margin-top: 25%;
  }
  @media (max-width: 1024px) {
    width: 80px !important;
    height: 80px !important;
    margin-left: 10px !important;
    margin-top: 50%;
  }
}

.client-img {
  background-image: url('../assets/img/client.png');
  background-size: cover;
}

.vehicle-img {
  background-image: url('../assets/img/vehicle.png');
  background-size: cover;
}

.table-detail {
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}

h4 {
  color: #3658d3;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

h3 {
  color: #3658d3;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.v-card__title {
  font-size: 22px;
}

.v-card__text {
  font-size: 18px;
}

th {
  margin-bottom: 0;
}

.v-data-table::v-deep
  .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  padding: 0px;
  padding-left: 20px;
}

.serviceCard {
  border: 2px solid #4867d7;
}

.headersRow {
  background-color: #e3f2fd;
  color: #757575;
  height: 50px;
  padding: 0 20px;
}

.estimate-card {
  background-color: #e4eaff;
  &.added-estimate {
    opacity: 0.5;
  }
}
</style>
