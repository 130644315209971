<template lang="pug">
v-container(fluid)
  v-row.my-2
    v-col.d-flex.justify-start(cols="6")
      v-menu(
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
        )
        template(v-slot:activator="{ on, attrs }")
          v-text-field(
            v-model="dateRangeText"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
            label="Ingresa un rango de fechas"
            clearable
            @click:clear="dates = []"
            )
        v-date-picker(
          v-model="dates"
          range
          no-title
          scrollable
          color="primary"
          locale="es-CL"
          )
          v-spacer
          v-btn(color="primary" @click="menu = false" outlined) Cerrar
          v-btn(color="primary" @click="handleSelectedMonth") Confirmar
    v-col.d-flex.justify-end(cols="6")
      v-btn(
        color="primary"
        outlined
        @click="exportToExcel"
        )
        v-icon mdi-file-excel
        | Exportar
  v-data-table(
    :headers="table.headers"
    :items="table.items"
    hide-default-footer
    :loading="loading"
    loading-text="Cargando datos..."
    no-data-text="No se encontraron datos"
    )
    template(v-slot:item.ot="{ item }")
      | OT N° {{ item.work_order }}
    template(v-slot:item.budget="{ item }")
      | Presupuesto N° {{ item.estimate }}
    template(v-slot:item.service_price="{ item }")
      | {{ item.service_price | currency }}
    template(v-slot:item.service_commission_percentage="{ item }")
      | {{ item.service_commission_percentage | currency }}
    template(v-slot:item.service_commission="{ item }")
      | {{ item.service_commission ? `${item.service_commission * 100}%` : 'Sin comisión' }}
    template(v-slot:item.date_assigned="{ item }")
      | {{ formatDate(item.estimate_updated_at) }}
    template(v-slot:item.date_finished="{ item }")
      | {{ formatDate(item.date_finished) }}
    template(v-slot:item.detail="{ item }")
      v-btn(icon color="primary" @click="$router.push({ name: 'Detalle Reparaciones', params: { otID: item.work_order }})")
        v-icon mdi-eye-outline
  v-pagination(v-model="page" :length="pageCount" circle color="primary" elevation=0)
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      table: {
        headers: [
          { text: 'OT', value: 'ot' },
          { text: 'Presupuesto', value: 'budget' },
          { text: 'Patente', value: 'vehicle_patent' },
          { text: 'Servicio Realizado', value: 'service_name' },
          { text: 'Precio Unitario Servicio', value: 'service_price' },
          { text: 'Precio Comisión', value: 'service_commission_percentage' },
          { text: '% de Comisión', value: 'service_commission' },
          { text: 'Fecha Asignado', value: 'date_assigned' },
          { text: 'Fecha Terminado', value: 'date_finished' },
          { text: 'Detalle', value: 'detail' }
        ],
        items: []
      },
      loading: false,
      menu: false,
      dates: [],
      picker: [],
      pickerFormated: '',
      page: 1,
      rowCount: 0
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.rowCount / 10)
    },
    dateRangeText () {
      const startDate = this.dates[0]?.split('-').reverse().join('/') || 'Desde'
      const endDate = this.dates[1]?.split('-').reverse().join('/') || 'Hasta'
      if(this.dates.length === 1) return `${startDate} - Hasta`
      return `${startDate}  -  ${endDate}`
    },
  },
  methods: {
    ...mapActions('ot', ['listJobsRepaired']),
    ...mapActions('inventory', ['exportJobsRepaired']),
    async fetchData (dateStart = null, dateEnd = null) {
      this.loading = true
      const params = {
        page: this.page,
        start_at__gte: dateStart,
        end_at__lte: dateEnd,
        is_job_list: true,
      }
      await this.listJobsRepaired({ params }).then(
        response => {
          this.table.items = response.data.results
          this.rowCount = response.data.count
          this.menu = false
        }
      )
      this.loading = false
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short', timeStyle: 'short', hour12: true }).format(new Date(date))
    },
    handleSelectedMonth () {
      this.$refs.menu.save(this.dates)
      this.selectedCorrectDate()
    },
    selectedCorrectDate () {
      if (this.dates[0] > this.dates[1]) {
        this.fetchData(this.dates[1], this.dates[0])
      } else {
        this.fetchData(this.dates[0], this.dates[1])
      }
    },
    setDates () {
      const date = new Date()
      const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 26)
      const lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate())
      this.dates = [firstDay.toISOString().split('T')[0], lastDay.toISOString().split('T')[0]]
      this.fetchData(this.dates[0], this.dates[1])
    },
    async exportToExcel () {
      this.loading = true
      await this.exportJobsRepaired({ params: { 'start_date': this.dates[0], 'end_date': this.dates[1] } }).then(
        response => {
          const blob = new Blob([response.data], {
            type: {
              type: response.headers['content-type']
            }
          })
          const blobUrl = window.URL.createObjectURL(blob)
          const tempLink = document.createElement('a')
          tempLink.style.display = 'none'
          tempLink.href = blobUrl
          tempLink.setAttribute('download', 'Historial de reparaciones.xlsx')
          document.body.appendChild(tempLink)
          tempLink.click()
          document.body.removeChild(tempLink)
          this.loading = false
        }
      )
    }
  },
  watch: {
    page () {
      if (this.dates.length === 2) {
        this.selectCorrectDate()
      } else {
        this.fetchData()
      }
    },
    picker (newVal) {
      if (this.picker.length === 2) {
        this.pickerFormated = this.formatDatePicker(this.picker).join('~')
      }
      if (newVal.length === 0) {
        this.fetchData()
      }
    },
    dates: {
      handler (value) {
        if(this.dates.length === 2) {
          const date1 = new Date(this.dates[0]).getTime();
          const date2 = new Date(this.dates[1]).getTime();
          if(date1 > date2) this.dates = this.dates.reverse()
        }
        if (this.dates.length === 0) {
          this.fetchData()
        }
      },
      deep: true
    },
  },
  created () {
    this.setDates()
  },
}
</script>
