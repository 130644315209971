<template lang="pug">
v-card(color="#4C7AEC").card.white--text
  .main-group
    .totals
      span Total OT Activas
      .number {{ total - totalByFilter.Entregado }}

    .filter-list
      v-btn.pa-0(
        v-for="filter in filters" :key="filter.name" text
        @click="toggleFilter(filter)"
      )
        v-avatar.tile(:color="filter.color" size="32")
          .number {{ totalByFilter[filter.name] || 0 }}
        .filter-text {{ filter.name }}

      v-menu(bottom offset-y nudge-right="-8" :close-on-content-click="false")
        template(#activator="{ on, attrs }")
          v-btn.pa-0(v-on="on" v-bind="attrs" text)
            v-avatar.tile(color="#FAFAFA" size="32")
              .number &#8230;
            .filter-text Otros

        v-card(color="#4C7AEC")
          .d-flex.flex-column.align-start.pa-2
            v-btn.pa-0(
              v-for="filter in moreFilters" :key="filter.name" text
              @click="toggleFilter(filter)"
            )
              v-avatar.tile(:color="filter.color" size="32")
                .number {{ totalByFilter[filter.name] || 0 }}
              .filter-text {{ filter.name }}
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { WORK_ORDER_FILTERS, WORK_ORDER_MORE_FILTERS } from './workOrderFilters'

export default {
  props: {
    totalByState: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapFields('OTListFilterStore', ['selectedFilters']),

    filters: () => WORK_ORDER_FILTERS,
    moreFilters: () => WORK_ORDER_MORE_FILTERS,
    allFilters: () => [...WORK_ORDER_FILTERS, ...WORK_ORDER_MORE_FILTERS],

    total() {
      return Object.values(this.totalByState).reduce((a, b) => a + b, 0)
    },

    totalByFilter() {
      return this.allFilters.reduce((acc, filter) => {
        acc[filter.name] = filter.states.reduce(
          (a, b) => a + (this.totalByState[b] || 0),
          0
        )
        return acc
      }, {})
    },
  },
  methods: {
    toggleFilter(filter) {
      const index = this.selectedFilters.indexOf(filter)
      if (index > -1) {
        this.selectedFilters.splice(index, 1)
      } else {
        this.selectedFilters.push(filter)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  min-height: 70px;

  @media (min-width: 770px) {
    // height: 9vh;
  }

  @media (min-width: 874px) {
    background-image: url('~@/assets/img/ot-filters.png');
    background-position: left 100% top 45%;
    background-size: 18vw auto;
    background-repeat: no-repeat;
  }

  @media (max-width: 1024px) {
    padding: 10px;
  }

  @media (max-width: 770px) {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding: 5px 10px;
    justify-content: center;
  }
}

.main-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  min-width: 500px;

  @media (max-width: 1440px) and (min-width: 1024px) {
    gap: 6vw;
  }

  @media (max-width: 1024px) and (min-width: 770px) {
    gap: 10px;
  }

  @media (max-width: 600px) {
    gap: 30px;
    min-width: auto;
    padding: 30px 30px 20px 25px;
    flex-direction: column;
  }
}

.totals {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  @media (max-width: 768px) {
    border-radius: 50%;
    border: 3px solid white;
    padding: 25px;
    flex-direction: column-reverse;
  }
  .number {
    font-size: 32px;
    line-height: 1;
    font-weight: 500;
  }
}

.filter-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  // grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  grid-gap: 5px 20px;
  justify-items: start;
  align-items: center;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 5px 10px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 3fr);
    grid-template-rows: repeat(3, 3fr);
    grid-gap: 30px 20px;
  }

  @media (max-width: 600px) {
    grid-template-columns: auto;
    grid-template-rows: repeat(3, 3fr);
    grid-gap: 50px 20px;
    .v-btn::v-deep .v-btn__content {
      display: flex;
      flex-direction: column;
      .filter-text {
        text-align: center;
      }
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: auto;
    grid-template-rows: repeat(3, 3fr);
    grid-gap: 50px 40px;
    margin-left: -30px;
  }
}

.tile {
  border-radius: 4px !important;
  .number {
    opacity: 0.8;
  }

  @media (max-width: 1024px) {
    min-width: 24px !important;
    width: 24px !important;
    height: 24px !important;
  }
  @media (max-width: 680px) {
    min-width: 32px !important;
    width: 40px !important;
    height: 40px !important;
  }
}


.filter-text {
  color: white;
  margin-left: 10px;
  min-width: 110px;
  text-align: left;

  @media (max-width: 1024px) {
    letter-spacing: 0.4px;
    margin-left: 4px;
  }
}

.filter-list > .v-btn:hover .tile {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}
</style>
