<template lang="pug">
v-container(v-if="service")
  v-card(:class="checkOwnership(service.assigned_to) ? '' : 'disabledCard'")
    v-row
      v-col(cols="10")
        v-chip.ml-5.mt-3(label :text-color="getStatusTextColor()" :color="getStatusColor()") Estado: {{service.date_finished ? 'Reparado' : 'En reparación'}}
        v-btn.ml-5.chevronButton(depressed style="background-color: #1A1796; color: #fff" :disabled="!checkOwnership(service.assigned_to) || service.date_finished !== null" @click="isOpen = !isOpen")
          p.mt-4.mr-1 Reparar
          v-icon(style="color: #fff;" dense) mdi-tools
        p.dateFinished(v-if="service.date_finished") Fecha de Término: {{ formatDate(service.date_finished)}}
      v-col(cols="2")
        v-avatar.mr-2(color="#FFD699" size=32 dark v-if="service.assigned_to") {{getAvatarText(service.assigned_to)}}
        v-avatar(v-else size=32 dark style="border-style: dotted;")
          v-icon(style="color: #464544;" dense) mdi-account-question-outline
    p.serviceName(style="color: #1A1796; font-weight: 600;") {{ service.service_name }} (x{{ service.service_quantity }})
    v-expansion-panels(flat v-model="panel")
      v-expansion-panel 
          v-expansion-panel-header(style="color: #2657C1; font-size: 18px; font-weight: 600") Repuestos Utilizados
          v-expansion-panel-content
            div
              v-data-table(dense :headers="tableRepair.headers" :items="service.sale_items" hide-default-footer mobile-breakpoint="0" class="elevation-0" no-data-text="No hay repuestos")
                template(v-slot:item.piece_name="{ item }")
                  p.mt-3.mb-1 
                    strong  {{ item.piece_code || '' }} {{ item.piece_name }} 
                    span(v-if="item.piece_brand_name") - {{ item.piece_brand_name }}
                template(v-slot:item.quantity="{ item }")
                  p.mt-3.mb-1 x{{ item.quantity }}
  v-dialog(v-model="isOpen" transition="dialog-top-transition" max-width="600")
    template(v-slot:default="dialog")
      v-card(tile)
        v-toolbar(color="#4C7AEC" dark) ¿Desea confirmar trabajo como REPARADO?
        v-container
        v-card-actions.justify-end
          v-btn.mr-5(@click="changeToRepaired()" color="#4C7AEC" dark :loading="loading") Sí
          v-btn(@click="isOpen = false" :loading="loading") No
</template>
<script>
import StateColorMixin from '../../mixins/StateColorMixin.vue'
import otStatusColors from '../../../shared/otStatusColors'
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      isOpen: false,
      loading: false,
      panel: 1,
      tableRepair: {
        headers: [
          { text: 'Repuestos', value: 'piece_name' },
          { text: 'Cantidad', value: 'quantity', align: 'center' }
        ]
      },
    }
  },
  mixins: [StateColorMixin],
  props: {
    service: {
      type: Object
    }
  },
  methods: {
    ...mapActions('service', ['jobDone']),
    getTechById (id) {
      return this.technicians.find(el => el.id === id)
    },
    getAvatarText (id) {
      const tech = this.getTechById(id)
      if (tech) {
        return tech.first_name.charAt(0) + tech.last_name.charAt(0)
      }
      return ''
    },
    getStatusTextColor () {
      return this.service.date_finished ? otStatusColors.Reparado.textColor : otStatusColors['En reparación'].textColor
    },
    getStatusColor () {
      return this.service.date_finished ? otStatusColors.Reparado.color : otStatusColors['En reparación'].color
    },
    changeToRepaired () {
      this.loading = true
      const data = {
        job_id: this.service.id
      }
      this.jobDone({ data }).then(
        (response) => {
          this.loading = false
          if (response.status === 200) {
            this.isOpen = false
            this.$emit('showAlert', { type: 'success', message: 'Trabajo Reparado con éxito', show: true, color: 'green' })
          }
        }
      )
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'short', timeStyle: 'short', hour12: true }).format(new Date(date))
    },
    checkOwnership (id) {
      const technician = this.technicians.filter(tech => tech.id === id)
      if (technician.length > 0 && this.user) {
        return technician[0].username === this.user.username
      }
      return false
    }
  },
  computed: {
    ...mapGetters('user', ['technicians']),
    ...mapGetters('auth', ['user'])
  }
}
</script>
<style lang="scss" scoped>
.chevronButton {
  min-width: 40px !important;
  margin-left: 5px;
  margin-top: 12px;
}
.v-avatar {
  display: flex;
  align-self: flex-end;
  justify-self: auto;
}
.disabledCard {
  opacity: 0.6;
}
.dateFinished {
  color: #1891b3;
  font-size: 15px;
  margin: 10px 0 0 20px;
  font-weight: 400;
}
.serviceName {
  margin: 10px 0 10px 20px;
  font-size: 18px;
}
</style>
