import { render, staticRenderFns } from "./FiltersCard.vue?vue&type=template&id=3575ae7e&scoped=true&lang=pug"
import script from "./FiltersCard.vue?vue&type=script&lang=js"
export * from "./FiltersCard.vue?vue&type=script&lang=js"
import style0 from "./FiltersCard.vue?vue&type=style&index=0&id=3575ae7e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3575ae7e",
  null
  
)

export default component.exports